import { companySetup } from 'Api/companySetup';
import { directDepositApi } from 'Api/directDeposit';
import { Col, Row } from 'antd';
import { Loader } from 'components/Global';
import Buttons from 'components/Global/Buttons';
import InputField from 'components/Global/InputField';
import InputFieldWithZero from 'components/Global/InputFieldWithZero';
import SelectDropdown from 'components/Global/SelectDropdown';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
	hasFormError,
	invalidText,
	toastText,
	validateFormData,
} from 'utils/utils';
import styles from './index.module.scss';
type DirectDepositProps = {
	onCancel: () => void;
	trueVerifiedAccount: () => void;
	falseVerifiedAccount: () => void;
	isDetailsLoading: boolean;
	paymentDetails: any;
	isUsd: boolean;
	apiCurrencyCode: any;
	setApiCurrencyCode: any;
	isStanbic: boolean;
	setIsStanbic: any;
	payrollDetails: any;
	setPayrollDetails: any;
};

const DirectDeposit = ({
	onCancel,
	falseVerifiedAccount,
	trueVerifiedAccount,
	paymentDetails,
	apiCurrencyCode,
	setApiCurrencyCode,
	isUsd,
	isStanbic,
	setIsStanbic,
	isDetailsLoading,
	payrollDetails,
	setPayrollDetails,
}: DirectDepositProps) => {
	const [isLoading, setIsLoading] = useState(false);

	const [isVerifyLoading, setIsVerifyLoading] = useState(false);

	const [searchParams] = useSearchParams();

	const [directDepositDetails, setDirectDepositDetails] =
		useState<any>(paymentDetails);

	const [formError, setFormError] = useState<any>({
		accountHolderName: false,
		accountNumber: false,
		bankType: false,
		sortCode: false,
		beneficiaryBankCode: false,
	});

	const [hasError, setHasError] = useState(false);

	const bankNameOptions = [
		{ label: 'First Capital Bank', value: 'FIRST_CAPITAL_BANK' },
		{ label: 'Stanbic Bank', value: 'STANBIC_BANK' },
	];

	const handleChange = (
		value: string | number | null,
		name: string,
		required: boolean,
		regex?: RegExp | null
	) => {
		if (required) {
			setHasError(invalidText(value));
		}

		if (typeof value === 'number') {
			if (value <= 0) {
				setHasError(true);
				return;
			}
		}

		if (typeof value === 'string' && regex) {
			const _regex = new RegExp(regex);
			setHasError(!_regex.test(value));
		}
		if (name === 'bankType') {
			if (value === 'STANBIC_BANK') {
				setIsStanbic(true);
			} else {
				setIsStanbic(false);
			}
			setApiCurrencyCode(isUsd ? 'USD' : 'ZMW');
			setDirectDepositDetails((prev: any) => {
				return {
					...prev,
					isVerified: false,
					accountNumber: '',
				};
			});
			falseVerifiedAccount();
		}

		if (name === 'accountNumber') {
			if (isStanbic) {
				setDirectDepositDetails((prev: any) => {
					return {
						...prev,
						isVerified: true,
					};
				});
				trueVerifiedAccount();
			} else {
				setDirectDepositDetails((prev: any) => {
					return {
						...prev,
						isVerified: false,
					};
				});
				falseVerifiedAccount();
			}
		}

		OnChange(value, name);
	};
	const OnChange = (value: string | number | null, key: string) => {
		setDirectDepositDetails((prev: any) => {
			return {
				...prev,
				[key]: value,
			};
		});

		const checkFormError = validateFormData(
			{ [key]: value },
			{ ...formError }
		);

		setFormError(checkFormError);
	};

	const handleSubmit = async () => {
		if (
			payrollDetails.isPayrollRun &&
			payrollDetails.previousOption === 'CASH'
		) {
			toastText(
				`Direct deposit changes cannot be saved - payroll status: ${payrollDetails.payrollStatus}`,
				'error'
			);
			return;
		}
		if (!isStanbic) {
			if (directDepositDetails.isVerified === false) {
				toastText('Account verification failed', 'error');
				return;
			}
		} else {
			setDirectDepositDetails((prev: any) => {
				return {
					...prev,
					isVerified: true,
				};
			});
			trueVerifiedAccount();
		}

		setDirectDepositDetails((prev: any) => {
			return {
				...prev,
				isVerified: true,
			};
		});

		let checkFormError = validateFormData(
			{ ...directDepositDetails },
			{ ...formError }
		);
		setFormError(checkFormError);

		if (hasFormError(checkFormError)) {
			return;
		} else {
			const data = {
				employeeId: searchParams.get('employeeId'),
				bankType: directDepositDetails.bankType,
				accountNumber: directDepositDetails.accountNumber,
				sortCode: directDepositDetails.sortCode,
				accountHolderName: directDepositDetails.accountHolderName,
				isVerified: directDepositDetails.isVerified,
				beneficiaryAddr1: directDepositDetails?.beneficiaryAddr1,
				beneficiaryAddr2: directDepositDetails?.beneficiaryAddr2,
				beneficiaryAddr3: directDepositDetails?.beneficiaryAddr3,
				beneficiaryBankCode: directDepositDetails.beneficiaryBankCode,
			};

			setIsLoading(true);

			try {
				await directDepositApi.createDirectDeposit(data);
				toastText(
					'Direct Deposit has been saved successfully',
					'success'
				);

				setPayrollDetails((prev: any) => ({
					...prev,
					previousOption: 'DIRECT_DEPOSIT',
				}));
			} catch (err: any) {
				const message =
					err.response?.data?.message ||
					'Something went wrong in creating Direct Deposit Request';
				toastText(message, 'error');
			}
			setIsLoading(false);
		}
	};

	const handleVerifySubmit = async () => {
		const currencyCode = isUsd ? 'USD' : 'ZMW';

		setIsVerifyLoading(true);
		try {
			const response = await companySetup.verifyAccountApi({
				accountId: directDepositDetails.accountNumber,
			});

			if (response?.data?.data) {
				const apiData = response.data.data;

				setApiCurrencyCode(apiData.accountCurrencyCode);

				setDirectDepositDetails((prev: any) => {
					return {
						...prev,
						sortCode: apiData.branchId,
					};
				});

				if (
					directDepositDetails.bankType === 'FIRST_CAPITAL_BANK' &&
					currencyCode !== apiData.accountCurrencyCode
				) {
					toastText(
						"Account and Employee's currency is different",
						'error'
					);
					setDirectDepositDetails((prev: any) => {
						return {
							...prev,
							isVerified: false,
						};
					});
				} else {
					trueVerifiedAccount();
					toastText('Account verified successfully', 'success');
					setDirectDepositDetails((prev: any) => {
						return {
							...prev,
							isVerified: true,
						};
					});
				}
			}
		} catch (err: any) {
			falseVerifiedAccount();
			setDirectDepositDetails((prev: any) => {
				return {
					...prev,
					isVerified: false,
				};
			});
			toastText('Account verification failed', 'error');
		} finally {
			setIsVerifyLoading(false);
		}
	};

	const handleCancel = () => {
		onCancel();
	};

	const verifyButton = {
		text: 'Verify',
		isLoading: isVerifyLoading,
		className: 'primary-button',
		fontSize: '1.8rem',
		minWidth: '12rem',
		minHeight: '4rem',
		isSubmit: true,
		onclick: () => {
			handleVerifySubmit();
		},
	};

	const myButtons = [
		{
			text: 'Cancel',
			isLoading: false,
			className: 'secondary-button',
			fontSize: '1.8rem',
			minWidth: '12rem',
			minHeight: '4rem',
			isSubmit: false,
			disabled: isLoading,
			onclick: handleCancel,
		},
		{
			text: 'Save',
			isLoading: isLoading,
			className: 'primary-button',
			fontSize: '1.8rem',
			minWidth: '12rem',
			minHeight: '4rem',
			isSubmit: true,
			onclick: handleSubmit,
			disabled: directDepositDetails.isVerified ? false : true,
		},
	];
	const isVerifyButton = [verifyButton];

	const beneficiaryCodeOptions = [
		{ label: 'SBICZMLX (Stanbic Bank)', value: 'SBICZMLX' },
		{ label: 'FRCGZMLU (First Capital Bank)', value: 'FRCGZMLU' },
	];

	return (
		<div className={styles['directDepositForm']}>
			<div className={styles['directDepositForm-container']}>
				{isDetailsLoading ? (
					<Loader />
				) : (
					<Row
						className={styles['directDepositForm-container-card']}
						gutter={20}
					>
						<Col
							span={24}
							className={`${styles['col']} ${styles['input']} margin-top-10`}
						>
							<InputField
								name="accountHolderName"
								value={directDepositDetails.accountHolderName}
								label="Account Holder Name"
								required={true}
								helperText="Account Holder must contain letters and spaces"
								onChange={(value: any) =>
									handleChange(
										value,
										'accountHolderName',
										true
									)
								}
								isError={formError.accountHolderName}
							/>
						</Col>
						<Col
							span={24}
							className={`${styles['col']} ${styles['input']} margin-top-10`}
						>
							<SelectDropdown
								placeholder="Bank Name"
								options={bankNameOptions || []}
								value={directDepositDetails.bankType}
								onChange={(value: any) =>
									handleChange(value, 'bankType', true)
								}
								size="large"
								required={true}
								helperText="Bank Name required"
								label="Bank Name"
								isError={formError.bankType}
							/>
						</Col>
						<Col
							span={
								!directDepositDetails.isVerified && !isStanbic
									? 18
									: 24
							}
							className={styles['col']}
						>
							<InputField
								name="accountNumber"
								value={directDepositDetails.accountNumber}
								label="Account Number"
								required={true}
								helperText="Account Number required"
								onChange={(value) =>
									handleChange(value, 'accountNumber', true)
								}
								isError={formError.accountNumber}
							/>
						</Col>
						{!directDepositDetails.isVerified && !isStanbic && (
							<Col span={6} className={styles['verfiyButton']}>
								{<Buttons buttons={isVerifyButton} />}
							</Col>
						)}

						<Col
							span={12}
							className={`${styles['col']} margin-top-10`}
						>
							<InputFieldWithZero
								name="sortCode"
								value={directDepositDetails.sortCode}
								label="Sort Code"
								required={true}
								helperText="Sort code must be a valid number"
								onChange={(value: any) =>
									handleChange(value, 'sortCode', true)
								}
								isError={formError.sortCode}
							/>
						</Col>
						{/* <Col
						span={12}
						className={`${styles['col']} ${styles['input']} margin-top-10`}
					>
						<InputField
							name="beneficiaryBankCode"
							value={directDepositDetails.beneficiaryBankCode}
							label="Beneficiary Bank Code"
							required={true}
							helperText="Beneficiary Bank Code required"
							onChange={(value: any) =>
								handleChange(value, 'beneficiaryBankCode', true)
							}
							isError={formError.beneficiaryBankCode}
						/>
					</Col> */}
						<Col
							span={12}
							className={`${styles['col']} margin-top-10`}
						>
							<SelectDropdown
								placeholder="Select Beneficiary Bank Code"
								options={[
									{
										label: 'Select Beneficiary Bank Code',
										value: '',
									},
									...beneficiaryCodeOptions,
								]}
								value={directDepositDetails.beneficiaryBankCode}
								label="Beneficiary Bank Code"
								required={true}
								onChange={(value: any) =>
									handleChange(
										value,
										'beneficiaryBankCode',
										true
									)
								}
								size="large"
								helperText="Beneficiary Bank Code  is required"
								isError={formError.beneficiaryBankCode}
							/>
						</Col>
						<Col
							span={24}
							className={`${styles['col']} ${styles['input']} margin-top-10`}
						>
							<InputField
								name="beneficiaryAddr1"
								value={directDepositDetails.beneficiaryAddr1}
								label="Beneficiary Address 1"
								required={false}
								onChange={(value: any) =>
									handleChange(
										value,
										'beneficiaryAddr1',
										true
									)
								}
								isError={formError.beneficiaryAddr1}
							/>
						</Col>
						<Col
							span={12}
							className={`${styles['col']} ${styles['input']} margin-top-10`}
						>
							<InputField
								name="beneficiaryAddr2"
								value={directDepositDetails.beneficiaryAddr2}
								label="Beneficiary Address 2"
								required={false}
								onChange={(value: any) =>
									handleChange(
										value,
										'beneficiaryAddr2',
										true
									)
								}
								isError={formError.beneficiaryAddr2}
							/>
						</Col>
						<Col
							span={12}
							className={`${styles['col']} ${styles['input']} margin-top-10`}
						>
							<InputField
								name="beneficiaryAddr3"
								value={directDepositDetails.beneficiaryAddr3}
								label="Beneficiary Address 3"
								required={false}
								onChange={(value: any) =>
									handleChange(
										value,
										'beneficiaryAddr3',
										true
									)
								}
								isError={formError.beneficiaryAddr3}
							/>
						</Col>
					</Row>
				)}

				<div className={styles['buttons']}>
					<Buttons buttons={myButtons} />
				</div>
			</div>
		</div>
	);
};
export default DirectDeposit;
