import { Select, Space } from 'antd';
import DatePickerField from 'components/Global/DatePicker';
import styles from './index.module.scss';

type Props = {
	dateValue: any;
	handleDatePicker: any;
	filterOptions: string;
	handleStatusFilter: (value: string) => void;
};

const PaymentTableHeader = (props: Props) => {
	const {
		handleDatePicker,
		dateValue,
		filterOptions,
		handleStatusFilter,
		// button,
	} = props;

	return (
		<div className={styles['employee-header']}>
			<Space>
				{/* <DatePickerField
					name={'dateFilter'}
					value={dateValue}
					required={false}
					picker="month"
					isError={false}
					onChange={handleDatePicker}
				/> */}
				<Select
					className={styles['employee-header-item']}
					value={filterOptions}
					style={{
						width: '180px',
					}}
					options={[
						{
							label: 'All',
							value: '',
						},
						{
							label: 'Success',
							value: 'SUCCESS',
						},
						{
							label: 'Pending',
							value: 'PENDING',
						},
						{
							label: 'Failed',
							value: 'FAILED',
						},
						{
							label: 'Processing',
							value: 'PROCESSING',
						},
					]}
					onChange={(value) => {
						handleStatusFilter(value);
					}}
					size="large"
					placeholder="Select by supervisor"
				/>
			</Space>
		</div>
	);
};

export default PaymentTableHeader;
