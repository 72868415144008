import { useEffect, useState } from 'react';
import { AddSvg } from 'utils/svgs';
import DocumentTable from './DocumentTable';
// import EmployeeHeader from './Header';
import { employeeApi } from 'Api/employee';
import { useSearchParams } from 'react-router-dom';
import { toastText } from 'utils/utils';
import AddDocumentModel from './AddDocument';
import DocumentHeader from './Header';
type Props = {
	isViewMode?: boolean;
};
const Documents = (props: Props) => {
	const { isViewMode = false } = props;
	// const navigate = useNavigate();

	const [isAddDocumentModalOpen, setIsAddSalaryModalOpen] = useState(false);
	const [searchParams, setSearchParams] = useSearchParams();
	const [documentData, setDocumentData] = useState<any>();
	const [isLoading, setIsLoading] = useState(false);

	const buttons = [
		{
			text: 'Add Document',
			isLoading: false,
			className: 'btn-blue',
			// icon: <AddSvg />,
			isSubmit: true,
			onclick: () => {
				setIsAddSalaryModalOpen(true);
			},
			disabled: isLoading,
		},
	];

	const handleCancel = () => {
		setIsAddSalaryModalOpen(false); // Close the modal
	};

	useEffect(() => {
		fetchDocumentData();
	}, [searchParams.get('employeeId')]);

	const fetchDocumentData = async () => {
		setIsLoading(true);
		try {
			const documents = await employeeApi.fetchEmployeeDocuments(
				searchParams.get('employeeId') as string
			);
			setDocumentData(documents?.data?.data);
		} catch (error: any) {
			const message =
				error?.response?.data?.message ||
				'some thing went wrong in fetch document data';
			toastText(message, 'error');
		}
		setIsLoading(false);
	};

	return (
		<div>
			<div>
				{!isViewMode && <DocumentHeader button={buttons} />}
				<DocumentTable
					documentData={documentData}
					isLoading={isLoading}
				/>
			</div>
			{!isViewMode && (
				<AddDocumentModel
					isAddDocumentModalOpen={isAddDocumentModalOpen}
					handleCancel={handleCancel}
					employeeId={searchParams.get('employeeId') as string}
					fetchDocumentData={fetchDocumentData}
				/>
			)}
		</div>
	);
};

export default Documents;
