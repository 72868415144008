import { CloseOutlined, EyeOutlined } from '@ant-design/icons';
import { Button, Modal, Typography } from 'antd';
import Title from 'antd/es/typography/Title';
import { bankIntegrationApi } from 'Api/bankIntegration';
import { Loader } from 'components/Global';
import { useEffect, useState } from 'react';
import { bankMapping } from 'utils/mappings';
import { decrypt, toastText } from 'utils/utils';
import AddPreviewModal from '../AddPreviewModel';
import styles from './index.module.scss';

type Props = {
	isOpen: boolean;
	handleCancel: () => void;
	isBankLoading: boolean;
	bankDetailsData: any;
	payRollId: string;
	handleBankDetails: any;
	currency: string;
};

interface BankLoadingState {
	[key: number]: boolean;
}

const AddModal = (props: Props) => {
	const {
		isOpen,
		handleCancel,
		isBankLoading,
		bankDetailsData,
		payRollId,
		handleBankDetails,
		currency,
	} = props;

	const [loadingStates, setLoadingStates] = useState<BankLoadingState>({});
	const [selectedBank, setSelectedBank] = useState<string | null>(null);
	const [isPreviewOpen, setIsPreviewOpen] = useState(false);
	const [checkedBankId, setCheckedBankId] = useState<number | null>(null);
	const [selectedPayrollId, setSelectedPayrollId] = useState<string | null>(
		payRollId
	);
	const [selectedBankData, setSelectedBankData] = useState<any>(null);

	const handleBankSelect = (bankId: string) => {
		setSelectedBank(bankId);
	};

	const handlePreview = () => {
		const bankData = bankDetailsData.find(
			(item: any) => item.id === selectedBank
		);

		setSelectedBankData(bankData);
		setIsPreviewOpen(true);
	};

	const handlePreviewCancel = () => {
		setIsPreviewOpen(false);
	};

	const handleProceedToPayment = () => {
		setIsPreviewOpen(false);
	};

	const handleCheckBalance = async (bankId: number) => {
		setCheckedBankId(bankId === checkedBankId ? null : bankId);

		// Set loading state for this specific bank
		setLoadingStates((prev) => ({
			...prev,
			[bankId]: true,
		}));

		try {
			const response = await bankIntegrationApi.checkBankBalance({
				bankId: bankId,
			});

			const balanceData = bankDetailsData.map((item: any) => {
				if (item.id === bankId) {
					return {
						...item,
						balance: response.data?.data?.balance,
					};
				}
				return item;
			});

			handleBankDetails(balanceData);
		} catch (error: any) {
			const message =
				error?.data?.message ||
				'Something went wrong in checking bank balance';
			toastText(message, 'error');
		} finally {
			// Clear loading state for this specific bank
			setLoadingStates((prev) => ({
				...prev,
				[bankId]: false,
			}));
		}
	};

	useEffect(() => {
		if (bankDetailsData && bankDetailsData.length > 0) {
			setSelectedBank(bankDetailsData[0].id);
		}
	}, []);

	useEffect(() => {
		if (payRollId) {
			setSelectedPayrollId(payRollId);
		}
	}, [payRollId]);

	return (
		<>
			<Modal
				open={isOpen}
				closable={false}
				onCancel={handleCancel}
				width={600}
				footer={
					<div
						style={{
							display: 'flex',
							justifyContent: 'flex-start',
						}}
					>
						<Button key="cancel" onClick={handleCancel}>
							Cancel
						</Button>

						{selectedBank && (
							<Button
								key="preview"
								onClick={handlePreview}
								type="primary"
								style={{ marginLeft: '10px' }}
							>
								Preview
							</Button>
						)}
					</div>
				}
			>
				<div className={`${styles['modal']} modal`}>
					<div className={styles['modal-header']}>
						<Title level={4}>Select Bank ({currency})</Title>

						<Button
							icon={<CloseOutlined />}
							onClick={handleCancel}
						/>
					</div>
					{isBankLoading && <Loader />}
					{!isBankLoading && (
						<div className={styles['modal-body']}>
							{bankDetailsData &&
								bankDetailsData.length > 0 &&
								bankDetailsData.map((item: any) => {
									const bank = bankMapping[item.bankType];
									return (
										<div
											key={item.id}
											className={`${
												styles['bank-option']
											} ${
												selectedBank === item.id
													? styles['selected']
													: ''
											}`}
											onClick={() =>
												handleBankSelect(item.id)
											}
										>
											<div
												className={
													styles['bank-details']
												}
											>
												<img
													src={bank?.logo}
													height={60}
													style={{
														objectFit: 'contain',
													}}
													width={120}
													alt={bank?.name}
												/>
												<div
													style={{
														fontSize: '16px',
														marginLeft: '10px',
													}}
												>
													{item.accountNumber
														? decrypt(
																item.accountNumber
														  )
														: ''}
												</div>
											</div>

											{checkedBankId === item.id && (
												<div
													className={
														styles['balance-info']
													}
												>
													<Typography.Text
														type="secondary"
														className={
															styles[
																'available-balance'
															]
														}
													>
														{item.balance}
													</Typography.Text>
													<div
														className={
															styles[
																'balance-amount'
															]
														}
													>
														{bank.balance}
													</div>
												</div>
											)}

											{item.isVerified &&
												item.bankType ===
													'FIRST_CAPITAL_BANK' && (
													<Button
														loading={
															loadingStates[
																item.id
															] || false
														}
														type="default"
														className={
															styles[
																'check-balance-button'
															]
														}
														onClick={(e) => {
															e.stopPropagation();
															if (
																item.isVerified
															) {
																handleCheckBalance(
																	item.id
																);
															}
														}}
														disabled={
															!item.isVerified ||
															item.bankType !==
																'FIRST_CAPITAL_BANK'
														}
													>
														<EyeOutlined
															style={{
																marginRight:
																	'2px',
															}}
														/>
														Check balance
													</Button>
												)}
										</div>
									);
								})}
						</div>
					)}
				</div>
			</Modal>

			{selectedPayrollId && selectedBank && currency && (
				<AddPreviewModal
					isOpen={isPreviewOpen}
					handleCancel={handlePreviewCancel}
					handleProceedToPayment={handleProceedToPayment}
					payrollId={selectedPayrollId}
					selectedBankId={selectedBank}
					selectedBankData={selectedBankData}
					currency={currency}
				/>
			)}
		</>
	);
};

export default AddModal;
