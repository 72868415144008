import { Table } from 'antd';
import styles from './index.module.scss';
import Ellipse from 'components/Global/Ellipse';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { formatDate } from 'utils/utils';

const { Column } = Table;

type Props = {
	ReportData: any;
	totalRecords: number;
	currentPage: number;
	pageSize: number;
	tableChangeHandler: (pagination: any, filter: any, sorter: any) => void;
	isLoading: boolean;
	downloadReport: (reportId: string) => Promise<void>;
	setDownloadingReportId: (id: string | null) => void;
	downloadingReportId: string | null;
};

const GlobalReportTable = (props: Props) => {
	const {
		ReportData,
		totalRecords,
		currentPage,
		pageSize,
		tableChangeHandler,
		isLoading,
		downloadingReportId,
		setDownloadingReportId,
		downloadReport,
	} = props;

		const { data: profileData } = useSelector(
			(state: any) => state?.userProfile
		);

	const handleDownload = async (reportId: string) => {
		setDownloadingReportId(reportId);
		if (downloadReport) {
			await downloadReport(reportId);
		}
		setDownloadingReportId(null);
	};
	return (
		<div className={styles['dynamic-table']}>
			<Table
				dataSource={ReportData}
				scroll={{ y: 'calc(100vh - 360px)' }}
				pagination={{
					total: totalRecords,
					current: currentPage,
					pageSize: pageSize,
					showSizeChanger: false,
					pageSizeOptions: [10, 20, 50, 100, 200],
				}}
				onChange={tableChangeHandler}
				rowKey={(record: any) => record.id}
				loading={isLoading}
			>
				<Column
					title="Pay Period Name"
					dataIndex="payPeriod"
					key="payPeriod"
					className="bg-white"
					render={(text) => text?.name}
				/>
				<Column
					title="Generated Date"
					dataIndex="createdAt"
					key="createdAt"
					sorter={true}
					className="bg-white"
					render={(value) => {
						return formatDate(
							value,
							profileData.companyDateFormat ?? 'DD/MM/YYYY'
						);
					}}
				/>
				<Column
					title="Generated By"
					dataIndex="createdBy"
					key="createdBy"
					className="bg-white"
					render={(text) => text?.fullName}
				/>
				<Column
					title="No. of Employees"
					dataIndex="noOfEmployees"
					key="noOfEmployees"
					width={'15%'}
					sorter={true}
					className="bg-white"
				/>
				<Column
					title="Report"
					dataIndex="documentLink"
					key="documentLink"
					className="bg-white"
					render={(text, record: any) => (
						<div>
							{record.documentUrl ? (
								<div>
									<div
										onClick={() =>
											downloadingReportId === record.id
												? () => {}
												: handleDownload(record.id)
										}
										style={{
											cursor:
												downloadingReportId ===
												record.id
													? 'not-allowed'
													: 'pointer',
											opacity:
												downloadingReportId ===
												record.id
													? 0.5
													: 1,
										}}
									>
										<Ellipse
											message={record.documentName}
											maxLength={50}
											key={record.documentName}
											tooltipMessage={record.documentName}
											isTooltip={true}
											isLink={true}
										/>
									</div>
								</div>
							) : (
								'-'
							)}
						</div>
					)}
				/>
			</Table>
		</div>
	);
};

export default GlobalReportTable;
