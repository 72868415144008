import { postApi, getApi, getApiPDF } from 'apis';
const createPersonalLevy = async (data: any) => {
	return await postApi('/personalLevy/create', data, true);
};

const getLatestPersonalLevyDetails = async () => {
	return await getApi('/personalLevy/latest');
};

const getAllPersonalLevyDetails = async (params: any) => {
	return await getApi(`/personalLevy/history`, params);
};
const downloadPersonalLevyAttachment = async (personalLevyId: string) => {
	return await getApiPDF('/personalLevy/attachment/download', {
		personalLevyId: personalLevyId,
	});
};

export const personalLevyApi = {
	createPersonalLevy,
	getAllPersonalLevyDetails,
	getLatestPersonalLevyDetails,
	downloadPersonalLevyAttachment,
};
