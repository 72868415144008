import {
	CheckCircleFilled,
	CheckCircleOutlined,
	CloseCircleOutlined,
	DollarOutlined,
	DownloadOutlined,
	InboxOutlined,
	UploadOutlined,
	WarningOutlined,
} from '@ant-design/icons';
import {
	Button,
	Col,
	Menu,
	Modal,
	Result,
	Row,
	Steps,
	Table,
	Tooltip,
	Upload,
	UploadFile,
} from 'antd';
import Dragger from 'antd/es/upload/Dragger';
import { employeeApi } from 'Api/employee';
import { Loader } from 'components/Global';
import GlobalPayPeriod from 'components/Global/PayPeriod';
import dayjs from 'dayjs';
import { useState } from 'react';
import { DeleteActionSvg, DownloadSvg } from 'utils/svgs';
import { convertArrayToCSV, invalidText, toastText } from 'utils/utils';
import styles from './index.module.scss';
import './index.scss';
const { Step } = Steps;

const steps = [
	{
		title: 'Upload CSV',
	},
	{
		title: 'Validation Results',
	},
	{
		title: 'Correct Data',
	},
	{
		title: 'Confirmation',
	},
];
const BulkSalaryImport = () => {
	const [isModalVisible, setIsModalVisible] = useState(false);

	const [fileList, setFileList] = useState<UploadFile[]>([]);
	const [isUploadLoading, setIsUploadLoading] = useState(false);

	const [validationResults, setValidationResults] = useState([]);

	const [finalData, setFinalData] = useState([]);

	const [isErrorModal, setIsErrorModal] = useState(false);
	const [errorData, setErrorData] = useState<any>(null);

	const [currentStep, setCurrentStep] = useState(0);
	const [isImportLoading, setIsImportLoading] = useState(false);

	const [selectedYear, setSelectedYear] = useState<any>(dayjs());
	const [selectedPayPeriod, setSelectedPayPeriod] = useState('');

	const [payPeriodError, setPayPeriodError] = useState(false);

	const handlePayPeriod = (value: string) => {
		if (invalidText(value)) {
			setPayPeriodError(true);
			return;
		}

		setSelectedPayPeriod(value);
	};

	const handleYearChange = (value: any) => {
		setSelectedYear(value);

		setSelectedPayPeriod('');
	};

	const handleModalClose = () => {
		setIsModalVisible(false);
		setErrorData(false);
		setFileList([]);
		setCurrentStep(0);
		setFinalData([]);
		setErrorData([]);
		setIsErrorModal(false);
		setIsImportLoading(false);
		setIsUploadLoading(false);
	};

	const handleImportMore = () => {
		setErrorData(false);
		setFileList([]);
		setCurrentStep(0);
		setFinalData([]);
		setErrorData([]);
		setIsErrorModal(false);
		setIsImportLoading(false);
		setIsUploadLoading(false);
	};

	const next = async () => {
		if (invalidText(selectedPayPeriod)) {
			toastText('Please select a pay period', 'error');
			return;
		}
		if (currentStep == 1) {
			const data = validationResults.filter((item: any) => item._isValid);
			setFinalData(data);
		}
		if (currentStep == 2) {
			await importSalaryDetails(finalData);
		} else {
			setCurrentStep((prev: any) => {
				return Math.min(prev + 1, steps.length - 1);
			});
		}
	};

	const prev = () => {
		setCurrentStep((prev) => Math.max(prev - 1, 0));
	};

	const getClassName = (isValid: boolean) => {
		if (!isValid) {
			return 'required-color';
		} else {
			return '';
		}
	};

	const handleDelete = (index: number) => {
		const updatedResults = validationResults.filter(
			(_, _index) => _index !== index
		);
		setValidationResults(updatedResults);

		if (currentStep === 2) {
			const updatedFinalData = finalData.filter(
				(_, _index) => _index !== index
			);
			setFinalData(updatedFinalData);
		}
		toastText('Record deleted Successfully', 'success');
	};

	const columns = [
		{
			title: 'Status',
			key: '_validationStatus',
			className: 'bg-white',
			width: 80,
			render: (_: any, record: any) =>
				record._isValid ? (
					<div
						className="text-green-500 bg-table"
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							color: 'green',
						}}
					>
						<CheckCircleOutlined />
					</div>
				) : (
					<div
						className="text-red-500 bg-table"
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							color: 'red',
						}}
					>
						<CloseCircleOutlined />
					</div>
				),
		},
		{
			title: 'Validation',
			key: '_errors',
			className: 'bg-white',
			width: 105,
			render: (_: any, record: any) => {
				return !record._isValid ? (
					<p
						onClick={() => {
							setIsErrorModal(true);
							setErrorData(record);
						}}
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							color: 'orange',
						}}
						className={`pointer ${getClassName(record._isValid)}`}
					>
						<WarningOutlined />
					</p>
				) : null;
			},
		},
		...Object.keys(validationResults[0] || {})
			.filter((key) => !key.startsWith('_'))
			.map((key) => ({
				title: key,
				dataIndex: key,
				key: key,
				width: 150,
				ellipsis: true, // Truncate if text overflows
				className: 'bg-table bg-white',
				render: (text: string, record: any) => (
					<div
						style={{ minWidth: 150 }}
						className={`${getClassName(record._isValid)}`}
					>
						{text}
					</div>
				),
			})),
		{
			title: 'Action',
			key: 'action',
			className: 'bg-white',
			width: 100,
			render: (_: any, record: any, index: number) => {
				return (
					<div
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<Tooltip title="Delete">
							<p
								className="cursor-pointer"
								onClick={() => {
									handleDelete(index);
								}}
							>
								<DeleteActionSvg />
							</p>
						</Tooltip>
					</div>
				);
			},
		},
	];

	const downloadCsv = async () => {
		try {
			const response = await employeeApi.downloadSalaryCsvTemplate();
			const url = window.URL.createObjectURL(
				new Blob([response.data], {
					type: 'text/csv',
				})
			);

			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `salary.csv`);
			document.body.appendChild(link);
			link.click();
			link.remove();
		} catch (err) {
			toastText(
				'Something went wrong in downloading csv template',
				'error'
			);
		}
	};

	const handleUpload = async (file: any) => {
		const reader = new FileReader();
		reader.onload = async (e: any) => {
			try {
				setIsUploadLoading(true);

				let formData = new FormData();
				formData.append('documentName', 'employee_csv');

				formData.append('file', file);

				const validatedData: any =
					await employeeApi.validateDataFromAPIForSalary(
						formData,
						selectedPayPeriod
					);

				setValidationResults(validatedData.data?.data);
				setIsModalVisible(true);

				setCurrentStep(1);
			} catch (error) {
				console.error(
					'Error during file upload and validation:',
					error
				);
				toastText('Failed to process file. Please try again', 'error');
			} finally {
				setIsUploadLoading(false);
			}
		};

		reader.onerror = () => {
			toastText('File reading failed', 'error');
		};

		reader.readAsText(file);

		return false;
	};

	const importSalaryDetails = async (data: any) => {
		try {
			setIsImportLoading(true);

			const csvString = convertArrayToCSV(data);

			const blob = new Blob([csvString], { type: 'text/csv' });

			const formData = new FormData();
			formData.append('file', blob, 'data.csv');

			const response = await employeeApi.importSalaryDetails(
				formData,
				selectedPayPeriod
			);
			toastText(response.data.message, 'success');
			setCurrentStep(3);
		} catch (error: any) {
			let errorMessage = '';
			if (error.response?.data?.responseStatus === 401) {
				errorMessage =
					'Something went wrong please while importing mid month details';
			} else {
				errorMessage = error.response?.data?.message;
			}
			toastText(errorMessage, 'error');
		} finally {
			setIsImportLoading(false);
		}
	};

	return (
		<>
			{/* <Tooltip title="Download Salary CSV Template">
				<p className={styles['csv-button']} onClick={downloadCsv}>
					<DownloadSvg />
				</p>
			</Tooltip>
			<Tooltip title="Upload Salary CSV">
				<p
					className={styles['csv-button']}
					onClick={() => setIsModalVisible(true)}
				>
					<UploadSvg />
				</p>
			</Tooltip> */}

			<Tooltip title="Mid-Month Action">
				<p style={{ padding: '5px', color: '#584495' }}>
					<span style={{ fontSize: '18px' }}>
						<DollarOutlined />{' '}
					</span>
					Mid-Month Action
				</p>
				{/* <Menu.Item
					style={{ backgroundColor: 'white' }}
					key="3"
					onClick={downloadCsv}
					icon={<DownloadOutlined />}
				>
					Download Salary CSV Template
				</Menu.Item> */}
				<Menu.Item
					style={{ backgroundColor: 'white' }}
					key="4"
					onClick={() => setIsModalVisible(true)}
					icon={<UploadOutlined />}
				>
					Upload Mid-Month CSV File
				</Menu.Item>
			</Tooltip>

			<Modal
				open={isModalVisible}
				onCancel={handleModalClose}
				width="1000px"
				footer={null}
				rootClassName="uploadCsvModal"
				closable={false}
				style={{
					maxHeight: 'calc(100vh - 200px)', // Fixed height for the modal
					display: 'flex',
					flexDirection: 'column',
					// overflowY: 'scroll',
				}}
			>
				<div>
					<Steps current={currentStep}>
						{steps.map((step, index) => (
							<Step key={index} title={step.title} />
						))}
					</Steps>

					<div
						style={{
							marginTop: '20px',
							marginBottom: '0px 20px 20px 20px',
							padding: '20px',
							border: '1px solid #d9d9d9',
							borderRadius: '5px',
							flex: 1,
							overflow: 'hidden',
							display: 'flex',
							flexDirection: 'column',
							minHeight: '200px',
							justifyContent: 'center',
						}}
					>
						{currentStep === 0 && (
							<div className={styles['document-upload']}>
								{isUploadLoading ? (
									<Loader />
								) : (
									<>
										<Row>
											<Col
												span={24}
												style={{
													// paddingBottom: '5px',
													paddingTop: '0px',
													display: 'flex',
													justifyContent: 'end',
												}}
											>
												<Tooltip title="Download Mid-Month CSV Template">
													<p
														className={
															styles[
																'template-link'
															]
														}
														onClick={downloadCsv}
													>
														<div
															style={{
																justifyContent:
																	'center',
																display: 'flex',
															}}
														>
															<DownloadSvg />
														</div>
														Download Template
													</p>
												</Tooltip>
											</Col>
										</Row>
										<Row>
											<Col
												span={12}
												style={{
													paddingBottom: '10px',
													paddingTop: '0px',
												}}
											>
												<GlobalPayPeriod
													selectedYear={selectedYear}
													handleSelectedYear={(
														value
													) => {
														handleYearChange(value);
													}}
													selectedPayPeriod={
														selectedPayPeriod
													}
													handlePayPeriod={(value) =>
														handlePayPeriod(value)
													}
													rowStyle={{
														marginTop: '10px',
														width: '100%',
														gap: '16px',
													}}
													payPeriodError={
														payPeriodError
													}
													isPayPeriodRequired
													isRow={false}
												/>
											</Col>
										</Row>
										<Dragger
											name="file"
											multiple={false}
											accept=".csv"
											fileList={fileList}
											beforeUpload={(file) => {
												const isCSV =
													file.type === 'text/csv' ||
													file.name
														.toLowerCase()
														.endsWith('.csv');

												if (!isCSV) {
													toastText(
														'You can only upload CSV files',
														'error'
													);
													return Upload.LIST_IGNORE;
												}
												if (
													invalidText(
														selectedPayPeriod
													)
												) {
													toastText(
														'Please select a pay period',
														'error'
													);
													return Upload.LIST_IGNORE;
												}
												handleUpload(file);
												return false;
											}}
											onChange={(info) => {
												let newFileList = [
													...info.fileList,
												];

												// Limit to one file
												newFileList =
													newFileList.slice(-1);

												setFileList(newFileList);
											}}
										>
											<p className="ant-upload-drag-icon">
												<InboxOutlined />
											</p>
											<p className="ant-upload-text">
												Click or drag CSV file to this
												area to upload
											</p>
											<p className="ant-upload-hint">
												Support for a single CSV file
												upload with{' '}
												<span
													style={{ color: '#584495' }}
												>
													500 records
												</span>
											</p>
										</Dragger>
									</>
								)}
							</div>
						)}

						{currentStep === 1 && (
							<div className="validation-results">
								<Table
									columns={columns}
									dataSource={validationResults ?? []}
									scroll={{
										x: 'max-content',
										y: 'calc(100vh - 400px)',
									}}
									pagination={false}
									size="middle"
									bordered
									tableLayout="fixed"
									footer={() => (
										<div style={{ textAlign: 'right' }}>
											Total No. of Employees :{' '}
											{validationResults?.length || 0}
										</div>
									)}
								/>
							</div>
						)}

						{currentStep === 2 && (
							<div className="validation-results">
								<Table
									columns={columns.slice(0, -2)}
									dataSource={finalData ?? []}
									scroll={{
										x: 'max-content',
										y: 'calc(100vh - 400px)',
									}}
									pagination={false}
									loading={isImportLoading}
									size="middle"
									bordered
									tableLayout="fixed"
									footer={() => (
										<div style={{ textAlign: 'right' }}>
											Total No. of Employees :{' '}
											{finalData?.length || 0}
										</div>
									)}
								/>
							</div>
						)}

						{currentStep === 3 && (
							<Result
								status="success"
								title="The process of importing mid month has started."
								subTitle={`Importing ${finalData.length} mid month record. Only ${finalData.length} is validated and imported. This may take a moment, Please wait....`}
								icon={
									<CheckCircleFilled
										style={{ color: '#584495' }}
									/>
								}
								extra={[
									<Button
										type="primary"
										key="console"
										onClick={handleImportMore}
									>
										Import More
									</Button>,
									<Button
										key="buy"
										onClick={handleModalClose}
									>
										Cancel
									</Button>,
								]}
							/>
						)}
					</div>

					{currentStep !== 3 && (
						<div
							style={{
								marginTop: '20px',
								display: 'flex',
								justifyContent: 'end',
								gap: '15px',
							}}
						>
							<Button onClick={handleModalClose}>Cancel</Button>
							{currentStep !== 0 && (
								<Button
									disabled={
										currentStep === 0 ||
										isImportLoading ||
										isUploadLoading
									}
									onClick={prev}
									type="primary"
								>
									Previous
								</Button>
							)}
							{fileList.length > 0 && (
								<Button
									disabled={
										currentStep === steps.length - 1 ||
										isImportLoading ||
										isUploadLoading
									}
									type="primary"
									onClick={next}
								>
									{currentStep === 2
										? 'Import Mid Month Details'
										: 'Next'}
								</Button>
							)}
						</div>
					)}
				</div>
			</Modal>

			{errorData && isErrorModal && (
				<Modal
					open={isErrorModal}
					onCancel={() => {
						setErrorData(null);
						setIsErrorModal(false);
					}}
					width="90%"
					footer={null}
				>
					<ul
						className="error-list"
						style={{
							listStyleType: 'none',
							padding: '10px',
							borderRadius: '5px',
							border: '1px solid #d4d4d4',
							color: '#721c24',
							fontFamily: 'Arial, sans-serif',
							// height: '550px',
							overflowY: 'auto',
						}}
					>
						{Object.entries(errorData._validationErrors).map(
							([field, error]: any) => (
								<li
									key={field}
									style={{
										padding: '8px 12px',
										borderBottom: '1px solid #d4d4d4',
									}}
								>
									<span
										style={{
											fontWeight: 'bold',
											color: '#b02a2a',
										}}
									>
										{field}
									</span>{' '}
									:{' '}
									<span
										style={{
											fontStyle: 'italic',
											color: 'black',
										}}
									>
										{error}
									</span>
								</li>
							)
						)}
					</ul>
				</Modal>
			)}
		</>
	);
};

export default BulkSalaryImport;
