import { Space, Table, Tag } from 'antd';
import {
	ApprovedByAccounts,
	CrossRejectedSvg,
	DeleteActionSvg,
	Paid,
	PendingForApproval,
} from 'utils/svgs';
import styles from './index.module.scss';
import dayjs from 'dayjs';
import {
	CheckCircleOutlined,
	CloseCircleOutlined,
	DollarOutlined,
	ExclamationCircleOutlined,
} from '@ant-design/icons';
import { useState } from 'react';
import ConfirmDelete from 'components/Global/confirmDeleteModel';
import { formatDate, minutesToTimeString, toastText } from 'utils/utils';
import { timeSheetsApi } from 'Api/timeSheet';
import { Link } from 'react-router-dom';
import AddModal from 'components/TimeSheet/AddModal';
import { useSelector } from 'react-redux';

const { Column } = Table;

type Props = {
	tableData: any;
	totalRecords: number;
	currentPage: number;
	pageSize: number;
	tableChangeHandler: (pagination: any, filter: any, sorter: any) => void;
	isLoading: boolean;
	fetchTimeSheets: any;
	//	showModal?: () => void;
};

const TimeLogsTable = (props: Props) => {
	const {
		tableData,
		totalRecords,
		currentPage,
		pageSize,
		tableChangeHandler,
		isLoading,
		fetchTimeSheets,
		//	showModal
	} = props;

	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [selectedTimeSheet, setSelectedTimeSheet] = useState(null);
	const [isDeleteLoading, setIsDeleteLoading] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [selectedSheetName, setSelectedSheetName] = useState('');

		const { data: profileData } = useSelector(
		(state: any) => state?.userProfile
	);


	const deleteHandler = async () => {
		try {
			setIsDeleteLoading(true);
			if (selectedTimeSheet) {
				await timeSheetsApi.deleteTimeSheet(selectedTimeSheet);
				fetchTimeSheets();
				setIsDeleteModalOpen(false);
			}
		} catch (err: any) {
			let message = 'Something went wrong in deleting time sheet';
			if (err.response?.data?.message) {
				message = err.response.data.message;
			}
			toastText(message, 'error');
		} finally {
			setIsDeleteLoading(false);
		}
	};
	const showModal = (timeSheet: any) => {
		setSelectedTimeSheet(timeSheet);
		setIsModalOpen(true);
	};

	const handleCancel = () => {
		setIsModalOpen(false);
	};

	return (
		<div className={styles['dynamic-table']}>
			<Table
				dataSource={tableData}
				pagination={{
					total: totalRecords,
					current: currentPage,
					pageSize: pageSize,
					showSizeChanger: false,
					pageSizeOptions: [10, 20, 50, 100, 200],
				}}
				onChange={tableChangeHandler}
				rowKey={(record) => record.id}
				loading={isLoading}
			>
				<Column
					title="Timesheet Name"
					dataIndex="timeSheetName"
					key="timeSheetName"
					// sorter={true}
					className="bg-white"
					render={(value: string, data: any) => {
						return (
							<span
								className="cursor-pointer"
								style={{ cursor: 'pointer', color: 'blue' }}
								onClick={() => {
									showModal(data);
								}}
							>
								{value}
							</span>
						);
					}}
				/>
				<Column
					title="Total Hours"
					dataIndex="totalHours"
					key="totalHours"
					// sorter={true}
					className="bg-white"
					render={(record: any) => {
						return minutesToTimeString(record);
					}}
				/>
				<Column
					title="Submitted By"
					dataIndex="submittedBy"
					key="submittedBy"
					// sorter={true}
					className="bg-white"
					render={(record: any, data: any) => {
						return data.createdBy.fullName;
					}}
				/>
				<Column
					title="Submitted On"
					dataIndex="submittedOn"
					key="submittedOn"
					// sorter={true}
					className="bg-white"
					render={(value) => {
						return formatDate(
							value,
							profileData.companyDateFormat ?? 'DD/MM/YYYY'
						);
					}}
				/>
				{/* <Column
					title="Status"
					dataIndex="status"
					key="status"
					// sorter={true}
					className="bg-white"
					render={(value) => {
						if (value === 'VERIFIED') {
							return (
								<Tag bordered={false} color="purple">
									<CheckCircleOutlined /> Verified
								</Tag>
							);
						}
						if (value === 'PENDING') {
							return (
								<Tag bordered={false} color="warning">
									<ExclamationCircleOutlined /> Pending
								</Tag>
							);
						}
						if (value === 'REJECTED') {
							return (
								<Tag bordered={false} color="red">
									<CloseCircleOutlined /> Rejected
								</Tag>
							);
						}
						if (value === 'PAID') {
							return (
								<Tag bordered={false} color="green">
									<DollarOutlined /> Paid
								</Tag>
							);
						}
					}}
				/> */}
				<Column
					title="Status"
					dataIndex="status"
					key="status"
					className="bg-white"
					width={'200px'}
					render={(value) => {
						let statusText = '';
						let statusClass = '';
						let StatusIcon = null;

						switch (value) {
							case 'PENDING':
								statusText = 'Pending';
								statusClass =
									styles['status-pending-for-approval'];
								StatusIcon = <PendingForApproval />;
								break;
							case 'VERIFIED':
								statusText = 'Verified';
								statusClass =
									styles['status-approved-by-accounts'];
								StatusIcon = <ApprovedByAccounts />;
								break;
							case 'REJECTED':
								statusText = 'Rejected';
								statusClass =
									styles['status-rejected-by-accounts'];
								StatusIcon = <CrossRejectedSvg />;
								break;
							case 'PAID':
								statusText = 'Paid';
								statusClass = styles['status-paid'];
								StatusIcon = <Paid />;
								break;
							default:
								statusText = 'Unknown Status';
								statusClass = styles['status-unknown'];
								StatusIcon = null;
						}
						return (
							<Tag
								bordered={false}
								className={`${styles['statusTag']} ${statusClass}`}
							>
								<div className={styles['statusIcon']}>
									{StatusIcon}
									<span>{statusText}</span>
								</div>
							</Tag>
						);
					}}
				/>
				<Column
					title="Action"
					key="action"
					// sorter={true}
					className="bg-white"
					render={(record, data: any) => {
						return (
							<Space>
								{/* {permissions.includes('time-logs-delete') && ( */}
								<Space
									onClick={() => {
										setSelectedTimeSheet(data.id);
										setIsDeleteModalOpen(true);
									}}
									className="pointer"
								>
									<DeleteActionSvg />
								</Space>
								{/* )} */}
							</Space>
						);
					}}
				/>
			</Table>
			{isModalOpen && (
				<AddModal
					isOpen={isModalOpen}
					handleCancel={handleCancel}
					selectedTimeSheet={selectedTimeSheet}
					// selectedShift={selectedShift}
					// selectedDate={selectedDate}
				/>
			)}
			{isDeleteModalOpen && selectedTimeSheet && (
				<ConfirmDelete
					handleCancel={() => setIsDeleteModalOpen(false)}
					handleOk={() => {
						return;
					}}
					isModalOpen={isDeleteModalOpen}
					deleteHandler={deleteHandler}
					isLoading={isDeleteLoading}
					deleteMessage="Delete?"
				/>
			)}
		</div>
	);
};

export default TimeLogsTable;
