import { FileDoneOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Spin, Table, Tag, Tooltip } from 'antd';
import Column from 'antd/es/table/Column';
import Ellipse from 'components/Global/Ellipse';
import dayjs from 'dayjs';
import { useContext, useState } from 'react';
import { Paid } from 'utils/svgs';
import UploadRecord from '../uploadTransferModel';
import styles from './index.module.scss';
import { useNavigate } from 'react-router-dom';
import { PermissionContext } from 'components/Global/AuthLayout';
import { payrollApi } from 'Api/payroll';
import { handleDownload, toastText } from 'utils/utils';

type TableProps = {
	tableData: any;
	totalRecords: number;
	currentPage: number;
	pageSize: number;
	tableChangeHandler: (pagination: any, filter: any, sorter: any) => void;
	isLoading: boolean;
	fetchAllPayrolls: any;
};

const PayrollHistoryTable = (props: TableProps) => {
	const [uploadRecordModal, setUploadRecordModal] = useState(false);
	const [payrollHistoryId, setPayrollHistoryId] = useState<any>(null);
		const [downloadingRowId, setDownloadingRowId] = useState<string | null>(
			null
		);
			const [downloadingCashRowId, setDownloadingCashRowId] = useState<
				string | null
			>(null);
	const {
		tableData,
		totalRecords,
		currentPage,
		pageSize,
		tableChangeHandler,
		isLoading,
		fetchAllPayrolls,
	} = props;

	const navigate = useNavigate();
	const context = useContext(PermissionContext);

	const isView = context.allowedPermissions.includes('View_Run_Payroll');

	const HandleViewModal = (id: string) => {
		navigate(`/run-payroll?payrollId=${id}&onlyView=true`);
	};

	const handleRecordDownload = async (
		recordId: string,
		recordName: string
	) => {
		return await handleDownload(
			recordId,
			payrollApi.exportHistoryCsvTemplate,
			setDownloadingRowId,
			recordName
			
		);
	};

		const handleCashRecordDownload = async (
			recordId: string,
			recordName: string
		) => {
			return await handleDownload(
				recordId,
				payrollApi.exportCashCsvTemplate,
				setDownloadingCashRowId,
				recordName
			);
		};

	const openUploadRecordModal = (historyId: string) => {
		setUploadRecordModal(true);
		setPayrollHistoryId(historyId);
	};

	const closeAllModal = () => {
		setUploadRecordModal(false);
		setPayrollHistoryId(null);
	};

	return (
		<div
			className={styles['dynamic-table']}
			style={{ width: '100%', overflowX: 'auto' }}
		>
			<Table
				dataSource={tableData}
				scroll={tableData.length > 0 ? { x: 1500 } : undefined}
				pagination={{
					total: totalRecords,
					current: currentPage,
					pageSize: pageSize,
					showSizeChanger: false,
					pageSizeOptions: [10, 20, 50, 100, 200],
				}}
				onChange={tableChangeHandler}
				rowKey={(record) => record.id}
				loading={isLoading}
			>
				<Column
					title="Pay Period"
					dataIndex="payPeriod"
					key="payPeriod"
					sorter={false}
					className="bg-white"
					width={260}
					fixed="left"
					render={(text, record: any) => {
						if (isView) {
							return (
								<span
									style={{ cursor: 'pointer', color: 'blue' }}
									onClick={() =>
										HandleViewModal(record.payrollId)
									}
								>
									{text}
								</span>
							);
						} else {
							return <span>{text}</span>;
						}
					}}
				/>
				<Column
					title="Currency"
					dataIndex="currency"
					key="currency"
					sorter={false}
					className="bg-white"
					width={150}
				/>
				<Column
					title="Pay Group"
					dataIndex="payGroup"
					key="payGroup"
					sorter={false}
					className="bg-white"
					width={200}
				/>
				<Column
					title="Approved By"
					dataIndex="approvedOn"
					key="approvedOn"
					sorter={false}
					className="bg-white"
					width={150}
				/>
				<Column
					title="Paid On"
					dataIndex="paidOn"
					key="paidOn"
					sorter={false}
					className="bg-white"
					render={(text) => dayjs(text).format('DD/MM/YYYY')}
					width={150}
				/>
				<Column
					title="Cash File"
					dataIndex="cashFile"
					key="cashFile"
					sorter={false}
					width={150}
					className="bg-white"
					render={(text, record: any) => (
						<div>
							{record?.cashFileLink ? (
								<div>
									<div
										onClick={() =>
											downloadingCashRowId === record.id
												? () => {}
												: handleCashRecordDownload(
														record.id,
														record.cashFileName
												  )
										}
										style={{
											cursor:
												downloadingCashRowId ===
												record.id
													? 'not-allowed'
													: 'pointer',
											opacity:
												downloadingCashRowId ===
												record.id
													? 0.5
													: 1,
										}}
									>
										<Ellipse
											message={record.cashFileName}
											maxLength={50}
											key={record.cashFileName}
											tooltipMessage={record.cashFileName}
											isTooltip={true}
											isLink={true}
										/>
									</div>
								</div>
							) : (
								'-'
							)}
						</div>
					)}
				/>
				<Column
					title="Bank File"
					dataIndex="bankFile"
					key="bankFile"
					width={150}
					sorter={false}
					className="bg-white"
					render={(text, record: any) => (
						<div>
							{record?.bankFileLink ? (
								<div>
									<div
										onClick={() =>
											downloadingRowId === record.id
												? () => {}
												: handleRecordDownload(
														record.id,
														record.bankFileName
												  )
										}
										style={{
											cursor:
												downloadingRowId === record.id
													? 'not-allowed'
													: 'pointer',
											opacity:
												downloadingRowId === record.id
													? 0.5
													: 1,
										}}
									>
										<Ellipse
											message={record.bankFileName}
											maxLength={50}
											key={record.bankFileName}
											tooltipMessage={record.bankFileName}
											isTooltip={true}
											isLink={true}
										/>
									</div>
								</div>
							) : (
								'-'
							)}
						</div>
					)}
				/>
				<Column
					title="Transfer Receipt"
					dataIndex="transferReceipt"
					key="transferReceipt"
					sorter={false}
					width={230}
					className="bg-white"
					render={(_: any, record: any) => (
						<div>
							<Button
								icon={<UploadOutlined />}
								type="text"
								style={{ color: '#584495' }}
								onClick={() => openUploadRecordModal(record.id)}
							>
								Upload file
							</Button>
							{record.transferReceiptLink ? (
								<a
									href={record.transferReceiptLink}
									target="_blank"
									rel="noopener noreferrer"
								>
									<Ellipse
										message={record.transferReceiptName}
										maxLength={20}
										key={record.transferReceiptName}
										tooltipMessage={
											record.transferReceiptName
										}
										isTooltip={true}
									/>
								</a>
							) : (
								<></>
							)}
						</div>
					)}
				/>
				<Column
					title="Status"
					dataIndex="status"
					key="status"
					sorter={false}
					width={150}
					className="bg-white"
					render={(status) => {
						return (
							<Tag bordered={false} color="green">
								<div className={styles['statusIcon']}>
									<Paid />
									<span>{status}</span>
								</div>
							</Tag>
						);
					}}
				/>
			</Table>

			{uploadRecordModal && (
				<UploadRecord
					open={uploadRecordModal}
					closeUploadRecordModal={closeAllModal}
					payrollHistoryId={payrollHistoryId}
					fetchAllPayrolls={fetchAllPayrolls}
				/>
			)}
		</div>
	);
};

export default PayrollHistoryTable;
