import { EditOutlined } from '@ant-design/icons';
import { Space, Table, Tag } from 'antd';
import { PermissionContext } from 'components/Global/AuthLayout';
import Ellipse from 'components/Global/Ellipse';
import dayjs from 'dayjs';
import { useContext } from 'react';
import {
	ApprovedByAccounts,
	CrossRejectedSvg,
	DeleteActionSvg,
	EditActionSvg,
	PendingForApproval,
} from 'utils/svgs';
import { invalidText } from 'utils/utils';
import styles from './index.module.scss';

const { Column } = Table;

type Props = {
	tableData: any;
	totalRecords: number;
	currentPage: number;
	pageSize: number;
	isLoading: boolean;
	showDeleteModal: () => void;
	setDeleteLeaveRequestId: any;
	handleEdit: any;
	tableChangeHandler: (pagination: any, filter: any, sorter: any) => void;
};
const LeaveRequestTable = (props: Props) => {
	const {
		tableData,
		totalRecords,
		currentPage,
		pageSize,
		isLoading,
		setDeleteLeaveRequestId,
		showDeleteModal,
		handleEdit,
		tableChangeHandler,
	} = props;
	const permissions = useContext(PermissionContext);
	const deleteDataHandler = (id: string) => {
		setDeleteLeaveRequestId(id);
		showDeleteModal();
	};

	const editDataHandler = (leaveObject: any) => {
		handleEdit(leaveObject);
	};
	return (
		<div>
			<Table
				dataSource={tableData}
				scroll={
					tableData.length > 0
						? { x: 1500, y: 'calc(100vh - 320px)' }
						: undefined
				}
				pagination={{
					total: totalRecords,
					current: currentPage,
					pageSize: pageSize,
					showSizeChanger: false,
					pageSizeOptions: [10, 20, 50, 100, 200],
				}}
				onChange={tableChangeHandler}
				rowKey={(record) => record.id}
				loading={isLoading}
			>
				<Column
					title="Employee Name | Code"
					dataIndex="employeeName"
					key="employeeName"
					filterSearch={true}
					// sorter={true}
					width={'250px'}
					fixed="left"
					className="bg-white"
					render={(text: string, record: any) => (
						<span>{`${text} | ${record.employeeCode}`}</span>
					)}
				/>
				<Column
					title={
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'start',
							}}
						>
							Leave Type
							<span
								style={{
									color: 'gray',
									fontWeight: 300,
									fontSize: '10px',
								}}
							>
								used | remaining
							</span>
						</div>
					}
					dataIndex="leaveType"
					width={'260px'}
					key="leaveType"
					// sorter={true}
					className="bg-white"
					render={(value: string, record: any) => {
						return (
							<>
								<Space size="middle">
									<span>{value}</span>
									<span>
										{record.usedLeaves} |{' '}
										{record.remainingLeaves === null
											? Infinity
											: record.remainingLeaves}{' '}
									</span>
								</Space>
							</>
						);
					}}
				/>
				<Column
					title="Start Date"
					dataIndex="startDate"
					key="startDate"
					sorter={true}
					width={'140px'}
					className="bg-white"
					render={(value) => {
						return dayjs(value).format('DD/MM/YYYY ');
					}}
				/>
				<Column
					title="End Date"
					dataIndex="endDate"
					key="endDate"
					sorter={true}
					width={'140px'}
					className="bg-white"
					render={(value) => (
						<div>
							{value ? dayjs(value).format('DD/MM/YYYY ') : '-'}
						</div>
					)}
					// render={(value) =>
					// 	value ? dayjs(value).format('DD/MM/YYYY ') : '-'
					// }
				/>
				<Column
					title="No. of Days"
					dataIndex="noOfDays"
					key="noOfDays"
					sorter={true}
					width={'140px'}
					className="bg-white"
				/>
				<Column
					title="Reason for Leave"
					dataIndex="reason"
					key="reason"
					className="bg-white"
					width={'200px'}
					render={(text) =>
						invalidText(text) ? (
							'-'
						) : (
							<Ellipse
								message={text}
								maxLength={20}
								key={text}
								tooltipMessage={text}
								isTooltip={true}
							/>
						)
					}
				/>
				<Column
					title="Leave Attachment"
					dataIndex="attachment"
					key="attachment"
					className="bg-white"
					width={'200px'}
					render={(text, record: any) => (
						<div>
							{record?.documentLink ? (
								<a
									href={record.documentLink}
									target="_blank"
									rel="noopener noreferrer"
								>
									<Ellipse
										message={record.documentName}
										maxLength={20}
										key={record.documentName}
										tooltipMessage={record.documentName}
										isTooltip={true}
									/>
								</a>
							) : (
								'-'
							)}
						</div>
					)}
				/>
				<Column
					title="Status"
					dataIndex="status"
					key="status"
					className="bg-white"
					width={'200px'}
					render={(value) => {
						let statusText = '';
						let statusClass = '';
						let StatusIcon = null;

						switch (value) {
							case 'PENDING':
								statusText = 'Pending';
								statusClass =
									styles['status-pending-for-approval'];
								StatusIcon = <PendingForApproval />;
								break;
							case 'APPROVED':
								statusText = 'Approved';
								statusClass =
									styles['status-approved-by-accounts'];
								StatusIcon = <ApprovedByAccounts />;
								break;
							case 'REJECTED':
								statusText = 'Rejected';
								statusClass =
									styles['status-rejected-by-accounts'];
								StatusIcon = <CrossRejectedSvg />;
								break;
							default:
								statusText = 'Unknown Status';
								statusClass = styles['status-unknown'];
								StatusIcon = null;
						}

						return (
							<Tag
								bordered={false}
								className={`${styles['statusTag']} ${statusClass}`}
							>
								<div className={styles['statusIcon']}>
									{StatusIcon}
									<span>{statusText}</span>
								</div>
							</Tag>
						);
					}}
				/>

				<Column
					title="Action"
					key="action"
					className="bg-white"
					width={'200px'}
					render={(_: any, record: any) => (
						<Space size="middle">
							{permissions.allowedPermissions.includes(
								'Edit_Leave_Request'
							) && record.status === 'PENDING' ? (
								<p
									className="cursor-pointer flex align-center justify-center"
									style={{ fontSize: 18 }}
									onClick={() => editDataHandler(record)}
								>
									<EditOutlined />
								</p>
							) : (
								<p className="cursor-not-allowed flex align-center justify-center">
									<EditActionSvg color="#928F8F" />
								</p>
							)}
							{permissions.allowedPermissions.includes(
								'Delete_Leave_Request'
							) && record.status === 'PENDING' ? (
								<p
									className="cursor-pointer flex align-center justify-center"
									style={{
										fontSize: 18,
										cursor: 'pointer',
									}}
									onClick={() => deleteDataHandler(record.id)}
								>
									<DeleteActionSvg />
								</p>
							) : (
								<p className="cursor-not-allowed flex align-center justify-center">
									<DeleteActionSvg color="#928F8F" />
								</p>
							)}
						</Space>
					)}
				/>
			</Table>
		</div>
	);
};

export default LeaveRequestTable;
