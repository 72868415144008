import { Table } from 'antd';
import Column from 'antd/es/table/Column';
import Buttons from 'components/Global/Buttons';
import Ellipse from 'components/Global/Ellipse';
import dayjs from 'dayjs';
import ReportPopOver from '../../ReportPopOver';
import { useContext, useState } from 'react';
import { PermissionContext } from 'components/Global/AuthLayout';
import { handleDownload } from 'utils/utils';
import { payeApi } from 'Api/payeApi';

type tableProps = {
	openUploadRecordModal: (payeRecordId: string) => void;
	calculatePayeCurrentPage: number;
	calculatePayePageSize: number;
	calculatePayeTotalRecords: number;
	calculatePayeIsLoading: boolean;
	calculatePayeData: any;
	tableChangeHandler: (pagination: any, filter: any, sorter: any) => void;
};
const TableComponent = (props: tableProps) => {
	const permissionContext = useContext(PermissionContext);
	const isAdd = permissionContext.allowedPermissions.includes(
		'Add_Statutory_Components'
	);

	const {
		openUploadRecordModal,
		calculatePayeCurrentPage,
		calculatePayePageSize,
		calculatePayeTotalRecords,
		calculatePayeIsLoading,
		calculatePayeData,
		tableChangeHandler,
	} = props;

	const [downloadingRowId, setDownloadingRowId] = useState<string | null>(
		null
	);
	const handleRecordDownload = async (recordId: string) => {
		const recordName = calculatePayeData.find(
			(record: any) => record.id === recordId
		)?.recordName;
		return await handleDownload(
			recordId,
			payeApi.downloadPayeRecord,
			setDownloadingRowId,
			recordName
		);
	};
	return (
		<Table
			dataSource={calculatePayeData}
			rowKey={(record: any) => record.id}
			loading={calculatePayeIsLoading}
			pagination={{
				total: calculatePayeTotalRecords,
				current: calculatePayeCurrentPage,
				pageSize: calculatePayePageSize,
				showSizeChanger: false,
				pageSizeOptions: [10, 20, 50, 100, 200],
			}}
			onChange={tableChangeHandler}
		>
			<Column
				title="Created Date"
				dataIndex="createdAt"
				key="createdAt"
				render={(text: any) => dayjs(text).format('DD/MM/YYYY')}
				sorter={true}
				width={'15%'}
				className="bg-white"
			/>
			<Column
				title="Currency"
				dataIndex="currency"
				key="currency"
				width={'10%'}
				className="bg-white"
			/>
			<Column
				title="Pay period name"
				dataIndex="payPeriodName"
				key="payPeriodName"
				className="bg-white"
				width={'20%'}
			/>
			<Column
				title="Calculated by"
				dataIndex="calculatedBy"
				key="calculatedBy"
				render={(text: any) => text}
				width={'12%'}
				className="bg-white"
			/>
			<Column
				title="PAYE Reports"
				dataIndex="payeReport"
				key="payeReport"
				className="bg-white"
				width={'15%'}
				render={(_: any, record: any) => (
					<ReportPopOver
						content={record.payeReport}
						reportType="PAYE"
					/>
				)}
			/>
			<Column
				title="PAYE Records"
				dataIndex=""
				key="docName"
				width={'28%'}
				render={(_: any, record: any) => (
					<div
						style={{
							display: 'flex',
							gap: '2rem',
							alignItems: 'center',
						}}
					>
						{isAdd && (
							<Buttons
								buttons={[
									{
										text: 'Upload',
										isLoading: false,
										className: 'btn-blue',
										isSubmit: false,
										disabled: false,
										onclick: () => {
											openUploadRecordModal(record.id);
										},
										fontSize: '1.5rem',
										minWidth: '7rem',
										minHeight: '2rem',
									},
								]}
							/>
						)}

						<div>
							{record.recordName && (
								<div>
									<div
										onClick={() =>
											downloadingRowId === record.id
												? () => {}
												: handleRecordDownload(
														record.id
												  )
										}
										style={{
											cursor:
												downloadingRowId === record.id
													? 'not-allowed'
													: 'pointer',
											opacity:
												downloadingRowId === record.id
													? 0.5
													: 1,
										}}
									>
										<Ellipse
											message={record.recordName}
											maxLength={50}
											key={record.recordName}
											tooltipMessage={record.recordName}
											isTooltip={true}
											isLink={true}
										/>
									</div>
									{/* Show progress bar only for the downloading row */}
									{/* {downloadingRowId === record.id && (
										<Progress
											percent={downloadProgress}
											size="small"
											style={{ marginTop: 8 }}
										/>
									)} */}
								</div>
							)}
						</div>
					</div>
				)}
			/>
		</Table>
	);
};

export default TableComponent;
