import { deleteApi, getApi, getApiCSV, postApi, putApi } from 'apis';

const createEmployee = async (data: any) => {
	return await postApi('/employee', data);
};
const getEmployeeDetailsById = async (id: string) => {
	return await getApi(`/employee/${id}`);
};

const getEmployeeActivityData = async (parmas: any) => {
	return await getApi(`/activity`, parmas);
};

const updateEmployee = async (employeeId: string, data: any) => {
	return await putApi(`/employee/${employeeId}`, data);
};

const createEmployeeEarnings = async (data: any) => {
	return await postApi('/employee/earning', data);
};

const getEmployeeEarnings = async (data: any) => {
	return await getApi('/employee/earning', data);
};

const getEmployeeDeductions = async (data: any) => {
	return await getApi('/employee/deduction', data);
};

const createEmployeeDeductions = async (data: any) => {
	return await postApi('/employee/deduction', data);
};

const uploadEmployeeDocument = async (data: any) => {
	return await postApi('/employee/upload-docs', data, true);
};

const fetchEmployeeDocuments = async (employeeId: string) => {
	return await getApi(`/employee/docs/${employeeId}`);
};

const deleteEmployee = async (employeeId: any) => {
	return await deleteApi(`/employee/delete/${employeeId}`);
};

const getHourlyEmployeeOptions = async (data: any) => {
	return await getApi('/employee/hourly-options', data);
};

const importEmployees = async (data: any) => {
	return await postApi('/csv/employee-import', data);
};

const importEmployeesWithFormData = async (data: any) => {
	return await postApi('/csv/employee-import', data, true);
};

const importSalaryDetails = async (data: any, payPeriodId: string) => {
	return await postApi(
		`/csv/salary-import?payPeriodId=${payPeriodId}`,
		data,
		true
	);
};

const validateDataFromAPI = async (data: any) => {
	return await postApi('/csv/employee-upload-csv', data, true);
};

const validateDataFromAPIForSalary = async (data: any, payPeriodId: string) => {
	return await postApi(
		`/csv/salary-upload?payPeriodId=${payPeriodId}`,
		data,
		true
	);
};

const downloadSalaryCsvTemplate = async () => {
	return await getApiCSV('/csv/salary-download');
};

const downloadEmployeeCsvTemplate = async () => {
	return await getApiCSV('/csv/employee-download');
};

const enCashLeave = async (data: any) => {
	return await postApi('/employee/encash-leave', data);
};
export const employeeApi = {
	createEmployee,
	getEmployeeDetailsById,
	getEmployeeActivityData,
	updateEmployee,
	createEmployeeEarnings,
	getEmployeeEarnings,
	getEmployeeDeductions,
	createEmployeeDeductions,
	uploadEmployeeDocument,
	fetchEmployeeDocuments,
	deleteEmployee,
	// 	uploadCsv,
	// 	downloadCsvTemplate,
	downloadSalaryCsvTemplate,
	downloadEmployeeCsvTemplate,
	getHourlyEmployeeOptions,
	validateDataFromAPI,
	importEmployees,
	importSalaryDetails,
	validateDataFromAPIForSalary,
	enCashLeave,
	importEmployeesWithFormData,
};
