import {
	DownCircleOutlined,
	ExclamationCircleOutlined,
} from '@ant-design/icons';
import { Button, Dropdown, Menu, Table, Tag } from 'antd';
import dayjs from 'dayjs';
import { CSVSvg, Paid, PaymentUnderProcess, SentToBank } from 'utils/svgs';
import styles from './index.module.scss';
import { formatDate } from 'utils/utils';
import { useSelector } from 'react-redux';

const { Column } = Table;

type Props = {
	tableData: any;
	totalRecords: number;
	currentPage: number;
	pageSize: number;
	tableChangeHandler: (pagination: any, filter: any, sorter: any) => void;
	isLoading: boolean;
	fetchMidMonthData: any;
	updateMidMonthStatus: (id: string, status: string) => void;
};

const MidMonthPayTable = (props: Props) => {
	const {
		tableData,
		totalRecords,
		currentPage,
		pageSize,
		tableChangeHandler,
		isLoading,
		fetchMidMonthData,
		updateMidMonthStatus,
	} = props;

const { data: profileData } = useSelector((state: any) => state?.userProfile);


	const handleMenuClick = (record: any, e: any) => {
		if (record.status === e.key) {
			return;
		} else {
			updateMidMonthStatus(record.id, e.key);
		}
	};

	const statusMenu = (record: any) => (
		<Menu onClick={(e) => handleMenuClick(record, e)}>
			<Menu.Item key="SENT_TO_BANK" className={styles['menuItem']}>
				<span className={styles['menuIcon']}>
					<SentToBank />
				</span>
				Sent to Bank
			</Menu.Item>
			<Menu.Item
				key="PAYMENT_UNDER_PROCESS"
				className={styles['menuItem']}
			>
				<span className={styles['menuIcon']}>
					<PaymentUnderProcess />
				</span>
				Payment under Process
			</Menu.Item>
			<Menu.Item key="PAID" className={styles.menuItem}>
				<span className={styles['menuIcon']}>
					<Paid />
				</span>
				Paid
			</Menu.Item>
		</Menu>
	);

	return (
		<div>
			<Table
				dataSource={tableData}
				scroll={{ y: 'calc(100vh - 300px)' }}
				pagination={{
					total: totalRecords,
					current: currentPage,
					pageSize: pageSize,
					showSizeChanger: false,
					pageSizeOptions: [10, 20, 50, 100, 200],
				}}
				onChange={tableChangeHandler}
				rowKey={(record) => record.id}
				loading={isLoading}
			>
				<Column
					title="Created Date"
					dataIndex="createdAt"
					key="createdAt"
					className="bg-white"
					sorter={true}
					width={'15%'}
					render={(value) => {
						return formatDate(
							value,
							profileData.companyDateFormat ?? 'DD/MM/YYYY'
						);
					}}
				/>
				<Column
					title="Details"
					dataIndex="createdAt"
					key="details"
					className="bg-white"
					width={'35%'}
					render={(createdAt) => {
						const formattedTime =
							dayjs(createdAt).format('hh:mm A');
						return (
							<div>
								This system-generated file was created on{' '}
								{dayjs(createdAt).format('DD/MM/YYYY')} at{''}{' '}
								{formattedTime}.{' '}
							</div>
						);
					}}
				/>
				<Column
					title="Status"
					dataIndex="status"
					key="status"
					className={`${styles['statusColumn']} bg-white`}
					render={(value, record) => {
						let statusText = '';
						let statusClass = '';
						let StatusIcon = null;

						if (value === 'SENT_TO_BANK') {
							statusText = 'Sent to Bank';
							statusClass = styles['status-sent-to-bank'];
							StatusIcon = <SentToBank />;
						} else if (value === 'PAYMENT_UNDER_PROCESS') {
							statusText = 'Payment under Process';
							statusClass =
								styles['status-payment-under-process'];
							StatusIcon = <PaymentUnderProcess />;
						} else if (value === 'PAID') {
							statusText = 'Paid';
							statusClass = styles['status-paid'];
							StatusIcon = <Paid />;
						} else {
							statusText = 'Please select status';
							statusClass = styles['status-placeholder'];
							StatusIcon = <ExclamationCircleOutlined />;
						}

						return (
							<div className={styles['statusContainer']}>
								<Dropdown
									overlay={statusMenu(record)}
									trigger={['click']}
								>
									<Button
										type="link"
										onClick={(e) => e.preventDefault()}
										className={styles['dropdownTrigger']}
									>
										<DownCircleOutlined />
									</Button>
								</Dropdown>

								<Tag
									bordered={false}
									className={`${styles['statusTag']} ${statusClass}`}
								>
									<div className={styles['statusIcon']}>
										{StatusIcon}
										<span>{statusText}</span>
									</div>
								</Tag>
							</div>
						);
					}}
				/>
				<Column
					title="File"
					dataIndex="documentLink"
					className="bg-white"
					key="documentLink"
					render={(text) => (
						<a
							href={text}
							rel="noopener noreferrer"
							className={styles['fileLink']}
						>
							<span className={styles['fileIcon']}>
								<CSVSvg />
							</span>
							Download
						</a>
					)}
				/>
			</Table>
		</div>
	);
};

export default MidMonthPayTable;
