import { Table, Tooltip } from 'antd';
import styles from './index.module.scss';
import { FaFilePdf, FaFileExcel } from 'react-icons/fa';
import dayjs from 'dayjs';
import { formatDate } from 'utils/utils';
import { useSelector } from 'react-redux';

const { Column } = Table;

type Props = {
	ReportData: any;
	totalRecords: number;
	currentPage: number;
	pageSize: number;
	tableChangeHandler: (pagination: any, filter: any, sorter: any) => void;
	isLoading: boolean;
	downloadReport: (reportId: string) => Promise<void>;
	setDownloadingReportId: (id: string | null) => void;
	downloadingReportId: string | null;
	downloadReportCSV: (reportId: string) => Promise<void>;
};

const JournalEntryReportTable = (props: Props) => {
	const {
		ReportData,
		totalRecords,
		currentPage,
		pageSize,
		tableChangeHandler,
		isLoading,
		downloadingReportId,
		setDownloadingReportId,
		downloadReport,
		downloadReportCSV,
	} = props;

	const { data: profileData } = useSelector(
		(state: any) => state?.userProfile
	);

	const handleDownload = async (reportId: string) => {
		setDownloadingReportId(reportId);
		if (downloadReport) {
			await downloadReport(reportId);
		}
		setDownloadingReportId(null);
	};

	const handleDownloadCSV = async (reportId: string) => {
		setDownloadingReportId(reportId);
		if (downloadReportCSV) {
			await downloadReportCSV(reportId);
		}
		setDownloadingReportId(null);
	};

	return (
		<div className={styles['dynamic-table']}>
			<Table
				dataSource={ReportData}
				scroll={{ y: 'calc(100vh - 360px)' }}
				pagination={{
					total: totalRecords,
					current: currentPage,
					pageSize: pageSize,
					showSizeChanger: false,
					pageSizeOptions: [10, 20, 50, 100, 200],
				}}
				onChange={tableChangeHandler}
				rowKey={(record: any) => record.id}
				loading={isLoading}
			>
				<Column
					title="Pay Period Name"
					dataIndex="payPeriod"
					key="payPeriod"
					className="bg-white"
					render={(text) => text?.name}
				/>
				<Column
					title="Generated Date"
					dataIndex="createdAt"
					key="createdAt"
					sorter={true}
					className="bg-white"
					render={(value) => {
						return formatDate(
							value,
							profileData.companyDateFormat ?? 'DD/MM/YYYY'
						);
					}}
				/>
				<Column
					title="Generated By"
					dataIndex="createdBy"
					key="createdBy"
					className="bg-white"
					render={(text) => text?.fullName}
				/>
				<Column
					title="Report"
					dataIndex="documentLink"
					key="documentLink"
					className="bg-white"
					render={(text, record: any) => (
						<div className="flex gap-2">
							<Tooltip title={record.pdfDocName}>
								<div
									onClick={() =>
										downloadingReportId === record.id
											? () => {}
											: handleDownload(record.id)
									}
									rel="noopener noreferrer"
									style={{
										cursor: 'pointer',
										opacity: 1,
									}}
								>
									<div className="flex items-center gap-1">
										<FaFilePdf color="#d32f2f" size={20} />
									</div>
								</div>
							</Tooltip>
							<Tooltip title={record.csvDocName}>
								<div
									onClick={() =>
										downloadingReportId === record.id
											? () => {}
											: handleDownloadCSV(record.id)
									}
									rel="noopener noreferrer"
									style={{
										cursor: 'pointer',
										opacity: 1,
									}}
								>
									<div className="flex items-center gap-1">
										<FaFileExcel
											color="#217346"
											size={20}
										/>
									</div>
								</div>
							</Tooltip>
						</div>
					)}
				/>
			</Table>
		</div>
	);
};

export default JournalEntryReportTable;
