import {
	DeleteOutlined,
	EditOutlined,
	ExclamationCircleOutlined,
	PlusOutlined,
} from '@ant-design/icons';
import { Button, Col, Collapse, CollapseProps, Modal, Row, Spin } from 'antd';
import { companyCurrencyApi } from 'Api/companyCurrency';
import { deleteApiWithData, getApi } from 'apis';
import { PermissionContext } from 'components/Global/AuthLayout';
import Buttons from 'components/Global/Buttons';
import ConfirmDelete from 'components/Global/confirmDeleteModel';
import SelectDropdown from 'components/Global/SelectDropdown';
import { currencyData, currencySymbols } from 'constants/CurrencyData';
import {
	currencyPositionDropdownOptions,
	decimalPlacesdropdownOptions,
	displayFormatdropdownOptions,
	negativeNumberdropdownOptions,
	thousandSeparatordropdownOptions,
} from 'constants/Data';
import { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/store';
import {
	hasFormError,
	invalidText,
	toastText,
	validateFormData,
} from 'utils/utils';
import { v4 as uuidv4 } from 'uuid';
import { fetchCompanyWizard } from '../../../../../../redux/actions/payrollSettingWizardAction';
import styles from './index.module.scss';
import { Loader } from 'components/Global';

type Props = {
	onCancel: () => void;
};

export const CurrencyDetails = (props: Props) => {
	const { onCancel } = props;
	const [isLoading, setIsLoading] = useState(false);
	const [isFetching, setIsFetching] = useState(false);
	const [isDeleteLoading, setIsDeleteLoading] = useState(false);

	const [isModalOpen, setIsModalOpen] = useState(false);
	const [currentDeleteId, setCurrentDeleteId] = useState<string | null>(null);
	const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
	const [currentEditId, setCurrentEditId] = useState<string | null>(null);

	const dispatch = useDispatch<AppDispatch>();
	const [currencySetups, setCurrencySetups] = useState<any[]>([]);

	const [hasError, setHasError] = useState(false);

	const [formErrors, setFormErrors] = useState<any[]>([
		{
			id: false,
			symbol: false,
			decimalPlaces: false,
			displayFormat: false,
			currencyPosition: false,
			negativeNumberDisplay: false,
			thousandSeparator: false,
		},
	]);

	const permissionContext = useContext(PermissionContext);
	const isAdd =
		permissionContext.allowedPermissions.includes('Add_Company_Setup');
	const isDelete = permissionContext.allowedPermissions.includes(
		'Delete_Company_Setup'
	);
	const getSymbolOptions = (currency: string) => {
		if (currency === 'ZMW') {
			return [
				{ label: 'ZMW', value: 'ZMW' },
				{ label: 'ZK', value: 'ZK' },
			];
		}
		const symbol = currencySymbols[currency];
		return [
			{ label: currency, value: currency },
			{ label: symbol, value: symbol },
		];
	};

	const buttons = [
		{
			text: 'Cancel',
			isLoading: false,
			className: 'btn-cancel',
			isSubmit: false,
			disabled: isLoading,
			onclick: () => {
				onCancel();
			},
			fontSize: '1.8rem',
			minWidth: '12rem',
			minHeight: '4rem',
		},
		{
			text: 'Save',
			isLoading: isLoading,
			className: 'btn-blue',
			isSubmit: false,
			disabled: false,
			onclick: () => {
				handleSubmit();
			},
			fontSize: '1.8rem',
			minWidth: '12rem',
			minHeight: '4rem',
		},
	];

	const confirmButtons = [
		{
			text: 'Proceed',
			isLoading: isLoading,
			className: 'btn-blue',
			fontSize: '1.8rem',
			minWidth: '12rem',
			minHeight: '4rem',
			isSubmit: true,
			onclick: () => {
				handleProceed();
			},
		},
		{
			text: 'Cancel',
			isLoading: false,
			className: 'secondary-button',
			fontSize: '1.8rem',
			minWidth: '12rem',
			minHeight: '4rem',
			isSubmit: false,
			onclick: () => {
				handleModalCancel();
			},
		},
	];

	const formatNumber = (
		num: number,
		thousandSeparator?: string,
		displayFormat?: number
	) => {
		const separator = thousandSeparator === 'DOT' ? '.' : ',';
		if (displayFormat === 1) {
			return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator);
		} else if (displayFormat === 2) {
			return num
				.toString()
				.replace(/\B(?=(\d{2})+(?!\d))/g, separator)
				.replace(
					new RegExp(`(\\d)${separator}(\\d{3})$`),
					`$1${separator}$2`
				);
		}
		return num.toString();
	};

	const formatCurrency = (setup: {
		symbol: string;
		currencyPosition: string;
		decimalPlaces?: number;
		negativeDisplay: string;
		displayFormat: number;
		thousandSeparator: string;
	}) => {
		const {
			symbol,
			currencyPosition,
			decimalPlaces = 2,
			negativeDisplay,
			displayFormat,
			thousandSeparator,
		} = setup;

		const value = 3500000;
		const millionValue = 3.5;
		const billionValue = 0.0035;

		// Format actual value
		let formattedValue = value.toFixed(decimalPlaces);
		formattedValue = formatNumber(
			parseFloat(formattedValue),
			thousandSeparator,
			displayFormat
		);

		// Format million value
		let formattedMillionValue = millionValue.toFixed(2);
		formattedMillionValue = formatNumber(
			parseFloat(formattedMillionValue),
			thousandSeparator
		);

		// Format billion value
		let formattedBillionValue = billionValue.toString();
		formattedBillionValue = formatNumber(
			parseFloat(formattedBillionValue),
			thousandSeparator
		);

		// Handle negative display
		if (negativeDisplay === 'MINUS') {
			formattedValue = `-${formattedValue}`;
		} else if (negativeDisplay === 'BRACKETS') {
			formattedValue = `(${formattedValue})`;
		}

		// Format each value based on currency position
		const actualValue =
			currencyPosition === 'BEFORE'
				? `${symbol} ${formattedValue}`
				: `${formattedValue} ${symbol}`;
		const millionValueFormatted =
			currencyPosition === 'BEFORE'
				? `${symbol} ${formattedMillionValue}m`
				: `${formattedMillionValue}m ${symbol}`;
		const billionValueFormatted =
			currencyPosition === 'BEFORE'
				? `${symbol} ${formattedBillionValue}b`
				: `${formattedBillionValue}b ${symbol}`;

		// Return values with HTML for styling
		return `
<div style="color: lightgrey;">
			<div style="display: flex; align-items: center;">
				<div style="text-align: center; margin-right: 20px;">
					<strong>Actual</strong><br/>${actualValue}
				</div>
				<div style="text-align: center; margin-right: 20px; border-left: 1px solid #ccc; padding-left: 20px;">
					<strong>Million</strong><br/>${millionValueFormatted}
				</div>
				<div style="text-align: center; border-left: 1px solid #ccc; padding-left: 20px;">
					<strong>Billion</strong><br/>${billionValueFormatted}
				</div>
			</div>
		</div>
	`;
	};

	const handleChange = (
		value: string | number | null | string[],
		name: string,
		//	stateName: string,
		setupId: string,
		required: boolean,
		regex?: RegExp | null
	) => {
		if (required && typeof value === 'string') {
			setHasError(invalidText(value));
		}
		if (required && Array.isArray(value) && value.length === 0) {
			setHasError(true);
		}

		if (typeof value === 'string' && regex) {
			const _regex = new RegExp(regex);
			setHasError(!_regex.test(value));
		}

		setCurrencySetups((prev) =>
			prev.map((setup: any) =>
				setup.id === setupId
					? {
							...setup,
							[name]: value,
					  }
					: setup
			)
		);

		// OnChange(value, name, stateName, setupId);
		OnChange(value, name, setupId);
	};

	const handleModalCancel = () => {
		setIsConfirmModalVisible(false);
		setCurrentEditId(null);
	};

	const handleProceed = () => {
		const _currencyDetails = currencySetups.map((b) => {
			if (b.id === currentEditId) {
				return { ...b, isDisabled: false };
			}
			return b;
		});
		setCurrencySetups(_currencyDetails);
		setIsConfirmModalVisible(false);
	};

	const OnChange = (
		value: string | number | null | string[],
		key: string,
		//	stateName: string,
		id: string
	) => {
		setCurrencySetups((prev) =>
			prev.map((setup) =>
				setup.id === id
					? {
							...setup,
							[key]: value,
					  }
					: setup
			)
		);

		const checkFormError = validateFormData(
			{ [key]: value },
			{ ...formErrors.find((error) => error.id === id) }
		);

		setFormErrors((prev) =>
			prev.map((error) =>
				error.id === id
					? {
							...error,
							...checkFormError,
					  }
					: error
			)
		);
	};

	const handleSubmit = async () => {
		let valid = false;

		const updatedErrors = currencySetups.map((setup) => {
			let setUpId = setup.id;

			const checkFormError = validateFormData(setup, {
				...formErrors.find((error) => {
					return error.id === setup.id;
				}),
			});

			delete setup.id;

			if (!hasFormError(checkFormError)) {
				valid = true;
			}

			setup.id = setUpId;
			return { ...checkFormError, id: setUpId };
		});
		setFormErrors(updatedErrors);

		let anyError = false;

		updatedErrors.forEach((currecnyError) => {
			if (Object.values(currecnyError).includes(true)) {
				anyError = true;
			}
		});

		if (anyError) {
			return;
		}

		if (!valid) {
			return;
		}
		// else {
		setIsLoading(true);

		const data: any = {
			currencies: currencySetups.map((setup) => ({
				id: setup.id,
				currency: setup.selectedCurrency,
				symbol: setup.symbol,
				decimalSeparator: parseInt(setup.decimalSeparator),
				currencyPosition: setup.currencyPosition,
				negativeDisplay: setup.negativeDisplay,
				displayFormat: parseInt(setup.displayFormat),
				thousandSeparator: setup.thousandSeparator,
				isBaseCurrency: setup.selectedCurrency === 'ZMW',
			})),
		};

		try {
			const currencyResponse =
				await companyCurrencyApi.createCompanyCurrency(data);
			const message =
				currencyResponse?.data?.message ||
				'Currency added successfully';
			setCurrencySetups(currencyResponse.data.data);
			dispatch(fetchCompanyWizard());
			toastText(message, 'success');
			onCancel();
		} catch (error: any) {
			const message =
				error?.response?.data?.message ||
				'Something went wrong in creating currency';
			toastText(message, 'error');

			// Handle network error
		} finally {
			setIsLoading(false);
		}
		//	}
	};
	// const handleSubmit = async () => {
	// 	let valid = false;
	// 	const updatedErrors = currencySetups.map((setup) => {
	// 		const checkFormError = validateFormData(setup, {
	// 			...formErrors.find((error) => error.id === setup.id),
	// 		});
	// 		if (hasFormError(checkFormError)) {
	// 			valid = false;
	// 		}
	// 		return checkFormError;
	// 	});
	// 	setFormErrors(updatedErrors);

	// 	if (valid) {
	// 		return;
	// 	} else {
	// 		setIsLoading(true);
	// 		const data: any = {
	// 			currencies: currencySetups.map((setup) => ({
	// 				currency: setup.selectedCurrency,
	// 				symbol: setup.symbol,
	// 				decimalSeparator: parseInt(setup.decimalSeparator),
	// 				currencyPosition: setup.currencyPosition,
	// 				negativeDisplay: setup.negativeDisplay,
	// 				displayFormat: parseInt(setup.displayFormat),
	// 				thousandSeparator: setup.thousandSeparator,
	// 				isBaseCurrency: setup.selectedCurrency === 'ZMW',
	// 				id: setup.id,
	// 			})),
	// 		};

	// 		try {
	// 			const currencyResponse =
	// 				await companyCurrencyApi.createCompanyCurrency(data);
	// 			const message =
	// 				currencyResponse?.data?.message ||
	// 				'Currency added successfully';
	// 			setCurrencySetups(currencyResponse.data.data);
	// 			dispatch(fetchCompanyWizard());
	// 			toastText(message, 'success');
	// 			onCancel();
	// 		} catch (error: any) {
	// 			const message =
	// 				error?.response?.data?.message ||
	// 				'Something went wrong in creating currency';
	// 			toastText(message, 'error');

	// 			// Handle network error
	// 		} finally {
	// 			setIsLoading(false);
	// 		}
	// 	}
	// };

	const fetchCurrencySetups = async () => {
		setIsFetching(true);
		try {
			const response = await getApi('/companyCurrency');
			const fetchedSetups = response.data.data.map(
				(setup: any, index: number) => ({
					id: setup.id,
					selectedCurrency: setup.currency,
					symbol: setup.symbol,
					decimalSeparator: setup.decimalSeparator,
					displayFormat: setup.displayFormat,
					currencyPosition: setup.currencyPosition,
					negativeDisplay: setup.negativeDisplay,
					thousandSeparator: setup.thousandSeparator,
					isDisabled: true,
				})
			);

			if (fetchedSetups.length === 0) {
				fetchedSetups.push({
					id: '1',
					// id: '',
					dbId: '',
					selectedCurrency: 'ZMW',
					symbol: '',
					decimalSeparator: '',
					displayFormat: '',
					currencyPosition: '',
					negativeDisplay: '',
					thousandSeparator: '',
					isDisabled: false,
				});
			}
			const _fetchSetups = [];
			const zambianCurrency = fetchedSetups.filter((setup: any) => {
				return setup.selectedCurrency === 'ZMW';
			});
			const otherCurrency = fetchedSetups.filter((setup: any) => {
				return setup.selectedCurrency !== 'ZMW';
			});
			_fetchSetups.push(zambianCurrency);
			_fetchSetups.push(otherCurrency);

			fetchedSetups[0] = zambianCurrency[0];
			otherCurrency.forEach((curr: any, index: number) => {
				fetchedSetups[index + 1] = curr;
			});
			setCurrencySetups(fetchedSetups);
			setFormErrors(
				fetchedSetups.map((setup: any) => ({
					id: setup.id,
					symbol: false,
					decimalSeparator: false,
					displayFormat: false,
					currencyPosition: false,
					negativeDisplay: false,
					thousandSeparator: false,
				}))
			);
		} catch (error) {
			console.error('Error fetching currency setups:', error);
		} finally {
			setIsFetching(false);
		}
	};

	useEffect(() => {
		fetchCurrencySetups();
	}, []);

	const addCurrencySetup = () => {
		if (currencySetups.length < 2) {
			const _id = uuidv4();
			const newCurrencySetup = {
				id: _id,
				isNew: true,
				selectedCurrency: '',
				symbol: '',
				decimalSeparator: '',
				displayFormat: '',
				currencyPosition: '',
				negativeDisplay: '',
				thousandSeparator: '',
			};

			setCurrencySetups([...currencySetups, newCurrencySetup]);
			setFormErrors((prev) => [
				...prev,
				{
					id: _id,
					// id: '',
					symbol: false,
					decimalSeparator: false,
					displayFormat: false,
					currencyPosition: false,
					thousandSeparator: false,
					negativeDisplay: false,
				},
			]);
		}
	};

	const showDeleteModal = (id: string) => {
		const currencyDetail = currencySetups.find((c) => c.id === id);
		if (currencyDetail?.isNew) {
			setCurrencySetups(currencySetups.filter((c) => c.id !== id));
		} else {
			setIsModalOpen(true);
			setCurrentDeleteId(id);
		}
	};

	const handleOk = async () => {
		if (currentDeleteId) {
			setIsDeleteLoading(true);
			try {
				await deleteApiWithData('/companyCurrency', {
					currencyId: currentDeleteId,
				});
				await fetchCurrencySetups();
				toastText('Currency deleted successfully', 'success');
			} catch (error: any) {
				let message = 'Something went wrong in deleting currency';

				if (error.response.data.message) {
					message = error.response.data.message;
				}

				toastText(message, 'error');
			} finally {
				setIsDeleteLoading(false);
				setIsModalOpen(false);
			}
		}
	};

	const handleCancel = () => {
		setIsModalOpen(false);
	};

	const showConfirmModal = (id: string) => {
		setCurrentEditId(id);
		setIsConfirmModalVisible(true);
	};

	const currencyOptions = Object.keys(currencyData).map((key) => ({
		label: `${key} - ${currencyData[key]}`,
		value: key,
		disabled: key === 'ZMW',
	}));

	const items: CollapseProps['items'] = currencySetups.map((setup, index) => {
		const isBaseCurrency = index === 0;
		return {
			key: setup.id,
			label: (
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						fontSize: '1.8rem',
						fontWeight: 500,
					}}
					className="color-purple"
				>
					<span>
						{isBaseCurrency
							? 'Base Currency (Zambian Kwacha)'
							: `Currency ${index + 1} (${
									currencyData[setup.selectedCurrency] ||
									'Select Currency'
							  })`}
					</span>
					<span
						style={{
							fontSize: '1.4rem',
							display: 'flex',
							gap: '2rem',
						}}
					>
						<div
							dangerouslySetInnerHTML={{
								__html: formatCurrency(setup),
							}}
						/>
						{/* {formatCurrency(setup)} */}
						{/* {isBaseCurrency
						? 'Base Currency (Zambian Kwacha)'
						: `Currency ${index + 1}(${
							currencyData[setup.selectedCurrency] ||
							'Select Currency'
						  })`} */}
						{/* <div> */}
						{setup.symbol && (
							<EditOutlined
								style={{
									cursor: 'pointer',
								}}
								onClick={() => showConfirmModal(setup.id)}
							/>
						)}
						{/* </div> */}
						{isDelete && !isBaseCurrency && (
							<DeleteOutlined
								style={{ color: 'red', cursor: 'pointer' }}
								onClick={() => showDeleteModal(setup.id)}
							/>
						)}
					</span>
				</div>
			),
			children: (
				<div className={styles['form-container']}>
					<Row className={styles['form-container-card']} gutter={50}>
						{!isBaseCurrency && (
							<Col span={24} className={styles['col']}>
								<SelectDropdown
									placeholder="Select Currency"
									options={currencyOptions}
									disabled={setup.isDisabled}
									value={setup.selectedCurrency}
									onChange={(value: any) => {
										handleChange(
											value,
											'selectedCurrency',
											//		'currencySetup',
											setup.id,
											true
										);
									}}
									size="large"
									required={true}
									helperText="Currency  is required"
									label="Currency"
									isError={
										formErrors.find(
											(error: any) =>
												error.id === setup.id
										)?.currency
									}
								/>
							</Col>
						)}
						<Col span={8} className={styles['col']}>
							<SelectDropdown
								placeholder="Symbol or Abbreviation"
								options={getSymbolOptions(
									setup.selectedCurrency
								)}
								value={setup.symbol}
								onChange={(value: any) => {
									handleChange(
										value,
										'symbol',
										//	'currencySetup',
										setup.id,
										true
									);
								}}
								size="large"
								required={true}
								helperText="Currency symbol is required"
								label="Currency symbol"
								disabled={
									!setup.selectedCurrency || setup.isDisabled
								}
								isError={
									formErrors.find(
										(error) => error.id === setup.id
									)?.symbol
								}
							/>
						</Col>
						<Col span={8} className={styles['col']}>
							<SelectDropdown
								placeholder="Currency Position"
								options={currencyPositionDropdownOptions}
								disabled={setup.isDisabled}
								value={setup.currencyPosition}
								onChange={(value: any) => {
									handleChange(
										value,
										'currencyPosition',
										//		'currencySetup',
										setup.id,
										true
									);
								}}
								size="large"
								required={true}
								helperText="Currency position is required"
								label="Currency position"
								isError={
									formErrors.find(
										(error) => error.id === setup.id
									)?.currencyPosition
								}
							/>
						</Col>
						<Col span={8} className={styles['col']}>
							<SelectDropdown
								placeholder="Decimal Places"
								options={decimalPlacesdropdownOptions}
								disabled={setup.isDisabled}
								value={setup.decimalSeparator}
								onChange={(value: any) => {
									handleChange(
										value,
										'decimalSeparator',
										//			'currencySetup',
										setup.id,
										true
									);
								}}
								size="large"
								required={true}
								helperText="Decimal places is required"
								label="Decimal places"
								isError={
									formErrors.find(
										(error) => error.id === setup.id
									)?.decimalSeparator
								}
							/>
						</Col>
						<Col span={8} className={styles['col']}>
							<SelectDropdown
								placeholder="Negative Number Display"
								options={negativeNumberdropdownOptions}
								disabled={setup.isDisabled}
								value={setup.negativeDisplay}
								onChange={(value: any) => {
									handleChange(
										value,
										'negativeDisplay',
										//			'currencySetup',
										setup.id,
										true
									);
								}}
								size="large"
								required={true}
								helperText="Negative number display is required"
								label="Negative number display"
								isError={
									formErrors.find(
										(error) => error.id === setup.id
									)?.negativeDisplay
								}
							/>
						</Col>
						<Col span={8} className={styles['col']}>
							<SelectDropdown
								placeholder="Display Format"
								options={displayFormatdropdownOptions}
								disabled={setup.isDisabled}
								value={setup.displayFormat}
								onChange={(value: any) => {
									handleChange(
										value,
										'displayFormat',
										//			'currencySetup',
										setup.id,
										true
									);
								}}
								size="large"
								required={true}
								helperText="Display format is required"
								label="Display format"
								isError={
									formErrors.find(
										(error) => error.id === setup.id
									)?.displayFormat
								}
							/>
						</Col>
						<Col span={8} className={styles['col']}>
							<SelectDropdown
								placeholder="Thousand Separator"
								options={thousandSeparatordropdownOptions}
								disabled={setup.isDisabled}
								value={setup.thousandSeparator}
								onChange={(value: any) => {
									handleChange(
										value,
										'thousandSeparator',
										//			'currencySetup',
										setup.id,
										true
									);
								}}
								size="large"
								required={false}
								helperText="Thousand Separator is required"
								label="Thousand Separator"
								isError={
									formErrors.find(
										(error) => error.id === setup.id
									)?.thousandSeparator
								}
							/>
						</Col>
					</Row>
				</div>
			),
		};
	});

	return (
		<div>
			{/* // <> */}
			<p className={styles['form-container-head-warning']}>
				<b>
					{' '}
					<sup>*</sup>
				</b>{' '}
				Indicated mandatory fields
			</p>
			<div className={styles['form-container']}>
				<div className={styles['company-header']}>
					<Row className={styles['header']} gutter={20}>
						{' '}
						<Col span={24}>
							<h2>Currency Setup</h2>{' '}
						</Col>{' '}
					</Row>

					{isAdd && (
						<Button
							// icon={<PlusOutlined />}
							type="primary"
							onClick={addCurrencySetup}
							style={{ minWidth: '12rem' }}
							size="large"
							disabled={currencySetups.length >= 2}
						>
							Add Currency
						</Button>
					)}
				</div>
				<div className={styles['scrollable-content']}>
					{!isFetching ? (
						<Collapse
							defaultActiveKey={['1']}
							items={items}
							expandIconPosition="start"
							className={styles['currency--employeeDetails']}
							expandIcon={({ isActive }) => (
								<div
									style={{
										transform: `rotate(${
											isActive ? -90 : 0
										}deg)`,
										transition: '0.3s',
									}}
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="16"
										height="16"
										fill="currentColor"
										className="bi bi-chevron-right"
										viewBox="0 0 16 16"
									>
										<path
											fillRule="evenodd"
											d="M1.646 4.646a.5.5 0 0 1 0 .708l6 6a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708.708L2.707 4.5 1.646 5.561a.5.5 0 0 1-.708-.708z"
										/>
									</svg>
								</div>
							)}
						/>
					) : (
						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								height: '100%',
							}}
						>
							<Loader />
						</div>
					)}
				</div>
				<Buttons buttons={buttons} />
				<ConfirmDelete
					isModalOpen={isModalOpen}
					handleOk={handleOk}
					handleCancel={handleCancel}
					deleteHandler={handleOk}
					isLoading={isDeleteLoading}
				/>
			</div>
			<Modal
				title={
					<>
						<div className={styles['confirm-modal']}>
							<ExclamationCircleOutlined
								style={{
									color: 'red',
									alignItems: 'center',
								}}
							/>
						</div>
					</>
				}
				className={styles['confirm-modal-container']}
				open={isConfirmModalVisible}
				closable={false}
				onCancel={handleModalCancel}
				footer={[
					<div
						key={'wrapper'}
						className={styles['confirm-modal__button']}
					>
						<Buttons buttons={confirmButtons}></Buttons>
					</div>,
				]}
			>
				<p className={styles['confirm-modal-text']}>
					You are trying to Edit critical details, We would not be
					able to proceed ahead with any payrolls until these details
					are not verified & approved by Admin.<br></br> Do you want
					to proceed
				</p>
			</Modal>
		</div>
	);
};
