import { getApi, postApi } from 'apis';
const uploadImage = async (data: any) => {
	return await postApi('/company/company-logo', data, true);
};

const getCompanyDetails = async () => {
	return await getApi('/company/details');
};

const getFinancialYear = async () => {
	return await getApi('/company/financial-year');
};

const verifyAccountApi = async (data: any) => {
	return await postApi('/bank-details/inquiry', data);
};

const getBankDetails = async () => {
	return await getApi('/company/bank-details');
};

const getCompanyLogo = async () => {
	return await getApi('/company/company-logo');
};

const verifyStanbicDetails = async (companyId: string) => {
	return await postApi(`/bank-details/verify`, {
		companyId,
	});
};

export const companySetup = {
	getCompanyDetails,
	getFinancialYear,
	verifyAccountApi,
	getBankDetails,
	uploadImage,
	getCompanyLogo,
	verifyStanbicDetails,
};
