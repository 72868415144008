import { payPeriodApi } from 'Api/payPeriod';
import { SideDrawerWrapper } from 'components/Global';
import { TablePagination, TableSorter } from 'interfaces/global.interface';
import { useEffect, useState } from 'react';
import { toastText } from 'utils/utils';
import AddPayPeriodSideDrawer from './AddPayPeriodSideDrawer';
import PayPeriodHeader from './Header';
import PayPeriodTable from './Table';
import dayjs from 'dayjs';
import useDebounce from 'components/Global/Hooks/UseDebounce';

const PayPeriodComponent = () => {
	const [currentPage, setCurrentPage] = useState(1);
	const [sortField, setSortField] = useState('');
	const [sortOrder, setSortOrder] = useState('');
	const [drawerAnimation, setDrawerAnimation] = useState<boolean>(false);
	const [isSideDrawerOpen, setSideDrawerOpen] = useState<boolean>(false);
	const [isHistoryLoading, setIsHistoryLoading] = useState(false);
	const [allPayPeriodData, setAllPayPeriodData] = useState<any>([]);
	const [searchText, setSearchText] = useState('');
	const [yearDate, setYearDate] = useState<any>(dayjs());
	const [editData, setEditData] = useState<any>(null);
	const [isEdit, setIsEdit] = useState(false);
	const debouncedSearchValue = useDebounce(searchText, 500);
	const handleSearch = (value: string) => {
		setSearchText(value);
	};

	const handleYearDatePicker = (value: any) => {
		setYearDate(value);
	};

	const handleSearchApi = async (value: string) => {
		//search api
	};

	const handleEdit = (data: any) => {
		setEditData(data);
		setIsEdit(true);
		sidebarDrawerOpen();
	};

	const tableChangeHandler = (
		pagination: TablePagination,
		filters: any,
		sorter: TableSorter
	) => {
		// setTotalRecords(pagination.total);
		setCurrentPage(pagination.current);
		// setPageSize(pagination.pageSize);
		setSortOrder(
			sorter?.order === 'ascend' ? 'asc' : sorter?.order ? 'desc' : ''
		);
		setSortField(sorter.field);
	};

	const sidebarDrawerOpen = () => {
		setDrawerAnimation(true);
		setSideDrawerOpen(true);
	};

	const removeDrawerFromDom = () => {
		setSideDrawerOpen(false);
	};

	const closeDrawerByAnimation = () => {
		setDrawerAnimation(false);
		setEditData(null);
		setIsEdit(false);
	};
	const fetchAllPayPeriodDetails = async () => {
		try {
			const query = {
				page: currentPage,
				sortBy: sortField,
				searchText: debouncedSearchValue,
				sortOrder: sortOrder,
				pageSize: 12,
				year: dayjs(yearDate).format('YYYY'),
			};

			setIsHistoryLoading(true);
			const allData = await payPeriodApi.getAllPayPeriods(query);
			const historyDetails = allData.data.data;

			setAllPayPeriodData(historyDetails);
		} catch (error: any) {
			if (error.response?.data?.error?.code !== 103) {
				toastText('Something went wrong in fetching history', 'error');
			} else {
				toastText(error.response?.data?.error?.message, 'error');
			}
		} finally {
			setIsHistoryLoading(false);
		}
	};
	useEffect(() => {
		fetchAllPayPeriodDetails();
	}, [currentPage, sortOrder, sortField, yearDate, debouncedSearchValue]);

	return (
		<>
			<div>
				<PayPeriodHeader
					sidebarDrawerOpen={sidebarDrawerOpen}
					PayPeriodExists={false}
					isLoading={isHistoryLoading}
					searchText={searchText}
					handleSearch={handleSearch}
					handleSearchApi={handleSearchApi}
					handleYearDatePicker={handleYearDatePicker}
					yearValue={yearDate}
				/>
				<PayPeriodTable
					payPeriodLatestData={allPayPeriodData}
					isLoading={isHistoryLoading}
					tableChangeHandler={tableChangeHandler}
					handleEdit={handleEdit}
				/>
			</div>
			{isSideDrawerOpen && (
				<SideDrawerWrapper
					isOpen={drawerAnimation}
					removeDrawerFromDom={removeDrawerFromDom}
					closeDrawerByAnimation={closeDrawerByAnimation}
					headerTitle={
						isEdit ? 'Edit Pay Period' : 'Add Pay Period'
					}
					position="right"
					width="half"
				>
					<AddPayPeriodSideDrawer
						closeDrawerByAnimation={closeDrawerByAnimation}
						// fetchLatestPayPeriodData={fetchLatestPayPeriodData}
						fetchAllPayPeriodDetails={fetchAllPayPeriodDetails}
						editData={editData}
						isEdit={isEdit}
					/>
				</SideDrawerWrapper>
			)}
		</>
	);
};

export default PayPeriodComponent;
