import ConfigurationSidebar from 'components/Global/ConfigurationSidebar';
import EmployeeApproval from 'components/Settings/Approval/Employee/EmployeeApproval';
import TaxationApproval from 'components/Settings/Approval/Employee/TaxationApproval';
import { FORMDATA } from 'constants/Data';
import { FC, useEffect, useState } from 'react';
import styles from './index.module.scss';
import { SettingsLayoutProps } from './types';

// Settings page layout
const ApprovalLayout: FC<SettingsLayoutProps> = (props) => {
	const { approvalMenuItems } = FORMDATA;
	const { children, onSideBarChange, selectedSidebar } = props;

	// const permissionContext = useContext(PermissionContext);
	// const { allowedPermissions } = permissionContext;

	const [menuItems, setMenuItems] = useState(approvalMenuItems);

	const fetchFields = () => {
		try {
		} catch (err) {}
	};

	useEffect(() => {
		fetchFields();
	}, []);

	const items = [
		{
			key: 'employmentDetails',
			label: 'Employment Details',
			children: <EmployeeApproval />,
		},
		{
			key: 'taxationDetails',
			label: 'Taxation Details',
			children: <TaxationApproval />,
		},
	];

	// useEffect(() => {
	// 	let _menuItems = approvalMenuItems;

	// 	if (!permissionContext.isSuperAdmin) {
	// 		_menuItems = _menuItems.filter((item) => item?.key !== 'company');
	// 	}
	// 	if (!allowedPermissions.includes('Allow_Branches')) {
	// 		_menuItems = _menuItems.filter(
	// 			(item) => item?.key !== 'branch-code'
	// 		);
	// 	}
	// 	if (!allowedPermissions.includes('Allow_Category')) {
	// 		_menuItems = _menuItems.filter(
	// 			(item) => item?.key !== 'category-code'
	// 		);
	// 	}
	// 	if (!allowedPermissions.includes('Allow_Job_Title')) {
	// 		_menuItems = _menuItems.filter((item) => item?.key !== 'job-title');
	// 	}
	// 	if (!allowedPermissions.includes('Allow_Pay_Point')) {
	// 		_menuItems = _menuItems.filter((item) => item?.key !== 'pay-point');
	// 	}
	// 	if (!allowedPermissions.includes('Allow_Departments')) {
	// 		_menuItems = _menuItems.filter(
	// 			(item) => item?.key !== 'department'
	// 		);
	// 	}
	// 	if (!allowedPermissions.includes('Allow_Cost_Center')) {
	// 		_menuItems = _menuItems.filter(
	// 			(item) => item?.key !== 'cost-center'
	// 		);
	// 	}
	// 	if (!allowedPermissions.includes('Allow_Job_Grade')) {
	// 		_menuItems = _menuItems.filter((item) => item?.key !== 'job-grade');
	// 	}

	// 	setMenuItems(_menuItems);
	// }, [approvalMenuItems, permissionContext]);

	// JSX
	return (
		<div className={styles['configuration-layout']}>
			<div className={styles['configuration-layout__wrapper']}>
				<ConfigurationSidebar
					items={menuItems}
					handleSidebar={onSideBarChange}
					selectedSidebar={selectedSidebar as string}
				/>
				<div className={styles['configuration-layout__body']}>
					{children}
				</div>
			</div>
		</div>
	);
};

export default ApprovalLayout;
