import { InfoCircleOutlined } from '@ant-design/icons';
import { taxationApi } from 'Api/taxation';
import { Col, Collapse, CollapseProps, Modal, Row, Tooltip } from 'antd';
import { PermissionContext } from 'components/Global/AuthLayout';
import Buttons from 'components/Global/Buttons';
import DatePickerField from 'components/Global/DatePicker';
import InputField from 'components/Global/InputField';
import SelectDropdown from 'components/Global/SelectDropdown';
import { currencyData } from 'constants/CurrencyData';
import { booleanOptions } from 'constants/Data';
import dayjs from 'dayjs';
import { TaxationInterface } from 'interfaces/employee.interface';
import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import {
	formatToDateOnly,
	hasFormError,
	invalidText,
	toastText,
	validateFormData,
} from 'utils/utils';
import styles from './index.module.scss';

type Props = {
	// taxationDetails: TaxationInterface;
	// onChange: (
	// 	stateName: string,
	// 	name: string,
	// 	value: string | number | null
	// ) => void;
	// handleSubmit: () => void;
	// formError: any;
	changeTab: (key: string) => void;
	onCancel: () => void;
	setIsUsd: any;
};

const TaxationDetails = (props: Props) => {
	const permissionsContext = useContext(PermissionContext);

	const { changeTab, onCancel } = props;
	const [employeeId, setEmployeeId] = useState<string | null>(null);
	const [searchParams] = useSearchParams();
	const [isTaxationCreated, setIsTaxationCreated] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isDisabled, setIsDisabled] = useState(false);
	const [previousTerminateDate, setPreviousTerminateDate] = useState('');
	const [showConfirmationModal, setShowConfirmationModal] = useState(false);
	const [changeTerminationSubmitLoading, setChangeTerminationSubmitLoading] =
		useState(false);
	const [isTerminationDateChange, setIsTerminationDateChange] =
		useState(false);
	const [isPayrollRun, setIsPayrollRun] = useState(false);
	const [previousCurrency, setPreviousCurrency] = useState('');
	const [isCurrencyChange, setIsCurrencyChange] = useState(false);

	const [taxationDetails, setTaxationDetails] = useState<TaxationInterface>({
		employeeTPIN: '',
		companyCurrencyId: '',
		taxationMethod: '',
		employmentType: '',
		enableGrossUpTax: false,
		disabilities: false,
		terminationDate: null,
		isCompensationFund: false,
	});

	const [formError, setFormError] = useState<any>({
		employeeTPIN: false,
		companyCurrencyId: false,
		taxationMethod: false,
		employmentType: false,
		enableGrossUpTax: false,
		disabilities: false,
		isCompensationFund: false,
	});
	const constantDropdownOptions = useSelector(
		(state: any) => state.constantDropdown.data
	);

	const mappedCurrencyOptions = constantDropdownOptions.currencyData.map(
		(currency: any) => ({
			value: currency.value,
			label: `${currency.label} - ${
				currencyData[currency.label] || currency.label
			}`,
			// label: currencyData[currency.label] || currency.label
		})
	);

	const openConfirmationModal = () => {
		setShowConfirmationModal(true);
	};

	const closeConfirmationModal = () => {
		setShowConfirmationModal(false);
	};

	const handleOkForModal = async () => {
		await handleSubmit(true);
		if (changeTerminationSubmitLoading === false) {
			setShowConfirmationModal(false);
			if (isTerminationDateChange) {
				changeTab('earnings');
			}
		}
	};

	const confirmModalButtons = [
		{
			text: 'No',
			className: 'secondary-button',
			fontSize: '1.8rem',
			minWidth: '12rem',
			minHeight: '4rem',
			isSubmit: false,
			isLoading: false,
			disabled: changeTerminationSubmitLoading,
			onclick: closeConfirmationModal,
		},
		{
			text: 'Yes',
			isLoading: changeTerminationSubmitLoading,
			className: 'btn-blue',
			fontSize: '1.8rem',
			minWidth: '90px',

			minHeight: '35px',
			isSubmit: false,
			onclick: handleOkForModal,
		},
	];

	const [hasError, setHasError] = useState(false);

	const buttons = [
		{
			text: 'Cancel',
			isLoading: false,
			className: 'btn-cancel',
			isSubmit: false,
			disabled: isLoading,
			onclick: () => {
				onCancel();
			},
			size: 'small',
			minWidth: '12rem',
			minHeight: '4rem',
		},
		{
			text: 'Save',
			isLoading: isLoading,
			className: 'btn-blue',
			isSubmit: false,
			disabled: isLoading,
			onclick: () => {
				checkForTerminateDate();
			},
			size: 'small',
			minWidth: '12rem',
			minHeight: '4rem',
		},
		{
			text: 'Back',
			isLoading: false,
			className: 'secondary-btn',
			isSubmit: false,
			disabled: isLoading,
			onclick: () => {
				changeTab('employmentDetails');
			},
			size: 'small',
			minWidth: '12rem',
			minHeight: '4rem',
		},
	];

	const handleChange = (
		value: string | number | null | string[] | boolean,
		name: string,
		stateName: string,
		required: boolean,
		regex?: RegExp | null
	) => {
		if (required && typeof value === 'string') {
			setHasError(invalidText(value));
		}
		if (required && Array.isArray(value) && value.length === 0) {
			setHasError(true);
		}

		if (typeof value === 'string' && regex) {
			const _regex = new RegExp(regex);
			setHasError(!_regex.test(value));
		}

		OnChange(value, name, stateName);
	};

	const OnChange = (
		value: string | number | null | string[] | boolean,
		key: string,
		stateName: string
	) => {
		switch (stateName) {
			case 'taxationDetails':
				setTaxationDetails((prev) => {
					return {
						...prev,
						[key]: value,
					};
				});
				break;
		}
		const checkFormError = validateFormData(
			{ [key]: value },
			{ ...formError }
		);
		setFormError(checkFormError);
	};

	const checkForTerminateDate = async () => {
		if (
			dayjs(taxationDetails.terminationDate).format('YYYY-MM-DD') !==
			dayjs(previousTerminateDate).format('YYYY-MM-DD')
		) {
			setIsTerminationDateChange(true);
			openConfirmationModal();
		} else if (
			previousCurrency &&
			previousCurrency !== taxationDetails.companyCurrencyId
		) {
			setIsCurrencyChange(true);
			openConfirmationModal();
		} else {
			await handleSubmit(false);
		}
	};

	const handleSubmit = async (isTerminationChange: boolean) => {
		let checkFormError = validateFormData(
			{
				...taxationDetails,
			},
			{ ...formError }
		);
		// checkFormError = {
		// 	...checkFormError,
		// 	employeeTPIN: !/^\d{10}$/.test(taxationDetails.employeeTPIN),
		// };

		setFormError(checkFormError);

		if (hasFormError(checkFormError)) {
			return;
		} else {
			isTerminationChange
				? setChangeTerminationSubmitLoading(true)
				: setIsLoading(true);
			if (!isTaxationCreated) {
				if (searchParams.get('employeeId')) {
					try {
						const data: any = {
							...taxationDetails,
							disabilities:
								(taxationDetails.disabilities as any as string) ===
									'true' ||
								(taxationDetails.disabilities as any) === true,
							isCompensationFund:
								(taxationDetails.isCompensationFund as any as string) ===
									'true' ||
								(taxationDetails.isCompensationFund as any) ===
									true,
							enableGrossUpTax:
								(taxationDetails.enableGrossUpTax as any as string) ===
									'true' ||
								(taxationDetails.enableGrossUpTax as any) ===
									true,
							employeeTPIN: Number(taxationDetails.employeeTPIN),
							id: searchParams.get('employeeId'),
							terminationDate: taxationDetails?.terminationDate
								? formatToDateOnly(
										taxationDetails?.terminationDate
								  )
								: null,
						};

						if (invalidText(data.terminationDate)) {
							delete data['terminationDate'];
						}

						const createdEmployee =
							await taxationApi.createTaxation(data);
						const message =
							createdEmployee?.data?.message ||
							'Employee taxation created successfully';
						props.setIsUsd(createdEmployee.data.isUsd);
						toastText(message, 'success');
						getTaxationDetail(
							searchParams.get('employeeId') as string
						);
						setIsTaxationCreated(true);
					} catch (err: any) {
						const message =
							err?.response?.data?.message ||
							'Something went wrong in creating taxation';
						toastText(message, 'error');
					}
				} else {
					toastText(
						'Create an employee before adding taxation details',
						'error'
					);
				}
			} else {
				if (searchParams.get('employeeId')) {
					const updateData = {
						...taxationDetails,
						employeeId: searchParams.get('employeeId') as string,
						disabilities:
							(taxationDetails.disabilities as any as string) ===
								'true' ||
							(taxationDetails.disabilities as any) === true,
						isCompensationFund:
							(taxationDetails.isCompensationFund as any as string) ===
								'true' ||
							(taxationDetails.isCompensationFund as any) ===
								true,
						enableGrossUpTax:
							(taxationDetails.enableGrossUpTax as any as string) ===
								'true' ||
							(taxationDetails.enableGrossUpTax as any) === true,
						employeeTPIN: Number(taxationDetails.employeeTPIN),
						terminationDate: taxationDetails?.terminationDate
							? formatToDateOnly(taxationDetails?.terminationDate)
							: null,
					};

					try {
						const updatedEmployeeTaxation =
							await taxationApi.updateTaxation(
								searchParams.get('employeeId') as string,
								updateData
							);

						const message =
							updatedEmployeeTaxation?.data?.message ||
							'Employee taxation updated successfully';
						props.setIsUsd(updatedEmployeeTaxation.data.isUsd);
						toastText(message, 'success');
						getTaxationDetail(
							searchParams.get('employeeId') as string
						);
					} catch (err: any) {
						const message = err?.response?.data?.message;
						toastText(
							message ||
								'Something went wrong in updating taxation',
							'error'
						);
					}
				} else {
					toastText(
						'Create an employee before adding taxation details',
						'error'
					);
				}
			}
			isTerminationChange
				? setChangeTerminationSubmitLoading(false)
				: setIsLoading(false);
		}
	};

	useEffect(() => {
		const employeeIdFromSearchParams = searchParams.get('employeeId');
		if (employeeIdFromSearchParams) {
			setEmployeeId(employeeIdFromSearchParams);
			getTaxationDetail(employeeIdFromSearchParams);
		}
	}, []);

	const getTaxationDetail = async (employeeId: string) => {
		try {
			const taxationData =
				await taxationApi.getTaxationDetailsByEmployeeId(employeeId);
			if (taxationData?.data?.data) {
				setIsTaxationCreated(true);
				const updatedTaxationDetails: any = { ...taxationDetails };
				setPreviousTerminateDate(
					taxationData?.data?.data?.terminationDate
				);
				setPreviousCurrency(
					taxationData?.data?.data?.companyCurrencyId
				);
				for (const key in taxationData?.data?.data) {
					if (key in taxationDetails) {
						updatedTaxationDetails[key] =
							taxationData?.data?.data[key];
					}
				}

				setTaxationDetails({
					...updatedTaxationDetails,
					terminationDate: !invalidText(
						updatedTaxationDetails.terminationDate
					)
						? dayjs(updatedTaxationDetails.terminationDate)
						: null,
				});

				setIsPayrollRun(taxationData.data.payrollRun);
			} else {
				setIsTaxationCreated(false);
			}
		} catch (error: any) {
			toastText(
				'Something went wrong in fetching employee details',
				'error'
			);
		}
	};

	const items: CollapseProps['items'] = [
		{
			key: '1',
			label: (
				<div
					style={{ fontSize: '1.8rem', fontWeight: 500 }}
					className="color-purple"
				>
					Taxation Details
				</div>
			),
			children: (
				<div className={styles['form-container']}>
					<Row className={styles['form-container-card']} gutter={50}>
						<Col span={6} className={styles['col']}>
							<InputField
								name="employeeTPIN"
								value={taxationDetails.employeeTPIN as string}
								label="Employee TPIN "
								required={true}
								// helperText="Employee TPIN must be a 10-digit number"
								onChange={(value) => {
									handleChange(
										value,
										'employeeTPIN',
										'taxationDetails',
										true
									);
								}}
								// regex="^\d{10}$"
								isError={formError.employeeTPIN}
								disabled={isDisabled}
							/>
						</Col>

						<Col span={6} className={styles['col']}>
							<SelectDropdown
								placeholder="Currency"
								options={mappedCurrencyOptions}
								value={taxationDetails.companyCurrencyId}
								onChange={(value) => {
									handleChange(
										value,
										'companyCurrencyId',
										'taxationDetails',
										true
									);
								}}
								size="large"
								required={true}
								helperText="Currency is required"
								label="Currency"
								disabled={isDisabled || isPayrollRun}
								extraLabel={
									isPayrollRun && (
										<>
											<Tooltip
												title={
													'You can change the currency after a current payroll is finalized'
												}
												placement="top"
											>
												<InfoCircleOutlined
													style={{
														paddingInline: '0.5rem',
													}}
												/>
											</Tooltip>
										</>
									)
								}
								isError={formError.companyCurrencyId}
							/>
						</Col>

						<Col span={6} className={styles['col']}>
							<SelectDropdown
								placeholder="Taxation Method"
								options={constantDropdownOptions.taxationMethod}
								value={taxationDetails.taxationMethod}
								onChange={(value) => {
									handleChange(
										value,
										'taxationMethod',
										'taxationDetails',
										true
									);
								}}
								size="large"
								required={true}
								helperText="Taxation method is required"
								label="Taxation Method"
								disabled={isDisabled}
								isError={formError.taxationMethod}
							/>
						</Col>
						<Col span={6} className={styles['col']}>
							<SelectDropdown
								placeholder="Employment Type "
								options={constantDropdownOptions.employmentType}
								value={taxationDetails.employmentType}
								onChange={(value) => {
									handleChange(
										value,
										'employmentType',
										'taxationDetails',
										true
									);
								}}
								size="large"
								required={true}
								helperText="Employment type is required"
								label="Employment Type"
								disabled={isDisabled}
								isError={formError.employmentType}
							/>
						</Col>
						<Col span={6} className={styles['col']}>
							<SelectDropdown
								placeholder="Enable Gross up tax "
								options={booleanOptions}
								value={String(taxationDetails.enableGrossUpTax)}
								onChange={(value) => {
									handleChange(
										value,
										'enableGrossUpTax',
										'taxationDetails',
										true
									);
								}}
								size="large"
								required={true}
								helperText="Enable gross up tax is required"
								label="Enable Gross up tax?"
								disabled={isDisabled}
								isError={formError.enableGrossUpTax}
							/>
						</Col>
						<Col span={6} className={styles['col']}>
							<SelectDropdown
								placeholder="Employee with Disabilities"
								options={booleanOptions}
								value={String(taxationDetails.disabilities)}
								onChange={(value) => {
									handleChange(
										value,
										'disabilities',
										'taxationDetails',
										true
									);
								}}
								size="large"
								required={true}
								helperText="Employee with Disabilities is required"
								label="Employee with Disabilities"
								disabled={isDisabled}
								isError={formError.disabilities}
							/>
						</Col>
						<Col span={6} className={styles['col']}>
							<DatePickerField
								label="Termination Date"
								name="terminationDate"
								value={
									taxationDetails.terminationDate as string
								}
								required={false}
								placeholder="Termination Date"
								onChange={(value) => {
									handleChange(
										value,
										'terminationDate',
										'taxationDetails',
										true
									);
								}}
								disabledBeforeDates={dayjs().startOf('day')}
								disabled={isDisabled}
								isError={formError.terminationDate}
							/>
						</Col>
						<Col span={6} className={styles['col']}>
							<SelectDropdown
								placeholder="Compensation Fund"
								options={booleanOptions}
								value={String(
									taxationDetails.isCompensationFund
								)}
								onChange={(value) => {
									handleChange(
										value,
										'isCompensationFund',
										'taxationDetails',
										true
									);
								}}
								size="large"
								required={true}
								helperText="Compensation Fund is required"
								label="Compensation Fund"
								disabled={isDisabled}
								isError={formError.isCompensationFund}
							/>
						</Col>
					</Row>
				</div>
			),
		},
	];

	useEffect(() => {
		if (
			employeeId &&
			!permissionsContext.allowedPermissions.includes('Edit_Employment')
		) {
			setIsDisabled(true);
		} else {
			setIsDisabled(false);
		}
	}, [employeeId, permissionsContext.allowedPermissions]);

	return (
		<>
			<div className={styles['form']}>
				<Collapse
					items={items}
					defaultActiveKey={['1', '2', '3']}
					expandIconPosition="end"
					className={styles['employeeDetails']}
					expandIcon={({ isActive }) => (
						<div
							style={{
								transform: `rotate(${isActive ? -90 : 0}deg)`,
								transition: '0.3s',
							}}
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="16"
								height="16"
								fill="currentColor"
								className="bi bi-chevron-right"
								viewBox="0 0 16 16"
							>
								<path
									fillRule="evenodd"
									d="M1.646 4.646a.5.5 0 0 1 0 .708l6 6a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708.708L2.707 4.5 1.646 5.561a.5.5 0 0 1-.708-.708z"
								/>
							</svg>
						</div>
					)}
					// onChange={onChangesss}
				/>
				<div className={styles['form-container']}>
					<div
						style={{
							display: 'flex',
							justifyContent: 'start',
							margin: '1rem 0rem ',
						}}
					>
						<Buttons buttons={buttons} />
					</div>
				</div>
			</div>
			{showConfirmationModal && (
				<>
					<Modal
						open={showConfirmationModal}
						onCancel={closeConfirmationModal}
						footer={null}
						closable={false}
						className="confirmation-modal"
						centered={true}
						width={350}
					>
						<div className={`${styles['modal']} modal`}>
							<div className={styles['modal-body']}>
								<p className="color-purple">
									{isCurrencyChange &&
										isTerminationDateChange &&
										'Would you like to encash leave before terminating the employee ? And after changing currency employee will be remove from the pay group! click yes to proceed'}
									{isCurrencyChange &&
										!isTerminationDateChange &&
										'After changing currency employee will be remove from the pay group! click yes to proceed'}
									{!isCurrencyChange &&
										isTerminationDateChange &&
										'Would you like to encash leave before terminating the employee ? click yes to proceed'}
								</p>
							</div>
							<div className={styles['modal-buttons']}>
								<Buttons buttons={confirmModalButtons} />
							</div>
						</div>
					</Modal>
				</>
			)}
		</>
	);
};

export default TaxationDetails;
