import React, { useState, useEffect } from 'react';
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons';
import { Button, Modal, Upload, message } from 'antd';
import type { UploadFile, RcFile } from 'antd/es/upload/interface';
import { companySetup } from 'Api/companySetup';
import { toastText } from 'utils/utils';
import styles from './index.module.scss';

const ImageUpload: React.FC = () => {
	const [previewVisible, setPreviewVisible] = useState(false);
	const [previewImage, setPreviewImage] = useState<string>('');
	const [previewTitle, setPreviewTitle] = useState('');
	const [fileList, setFileList] = useState<UploadFile[]>([]);
	const [logoLoading, setLogoLoading] = useState(false);
	const [buttonLoading, setButtonLoading] = useState(false);

	useEffect(() => {
		fetchCompanyLogo();
	}, []);

	const fetchCompanyLogo = async () => {
		try {
			setLogoLoading(true);
			const response = await companySetup.getCompanyLogo();
			const fetchedImageUrl = response.data.data;

			if (fetchedImageUrl) {
				setFileList([
					{
						uid: '-1',
						name: 'Company Logo',
						status: 'done',
						url: fetchedImageUrl,
					},
				]);
			}
		} catch (error) {
			toastText('Failed to fetch existing company logo', 'error');
		} finally {
			setLogoLoading(false);
		}
	};

	const handlePreview = async (file: UploadFile) => {
		if (!file.url && !file.preview) {
			file.preview = await getBase64(file.originFileObj as RcFile);
		}

		setPreviewImage(file.url || (file.preview as string));
		setPreviewVisible(true);
		setPreviewTitle(
			file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1)
		);
	};

	const handleChange = ({
		fileList: newFileList,
	}: {
		fileList: UploadFile[];
	}) => {
		setFileList(newFileList);
	};

	const handleCancel = () => setPreviewVisible(false);

	const beforeUpload = (file: RcFile) => {
		const isImage = file.type.startsWith('image/');
		const isLt1M = file.size / 1024 / 1024 < 1;

		if (!isImage) {
			toastText('You can only upload image files!', 'error');
			return Upload.LIST_IGNORE;
		}

		if (!isLt1M) {
			toastText('Image must be smaller than 1MB!', 'error');
			return Upload.LIST_IGNORE;
		}

		return false;
	};

	const uploadToBackend = async () => {
		if (fileList.length === 0 || fileList[0].originFileObj === undefined) {
			toastText('Please upload an image', 'error');
			return;
		}

		const formData = new FormData();
		formData.append('moduleName', 'companyLogo');
		formData.append('file', fileList[0].originFileObj as Blob);

		try {
			setButtonLoading(true);
			await companySetup.uploadImage(formData);
			toastText('File uploaded successfully', 'success');
			await fetchCompanyLogo(); // Refresh the logo after successful upload
		} catch (error) {
			toastText('Failed to upload image', 'error');
		} finally {
			setButtonLoading(false);
		}
	};
	const getBase64 = (file: RcFile): Promise<string> =>
		new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result as string);
			reader.onerror = (error) => reject(error);
		});

	const uploadButton = (
		<div>
			{logoLoading ? <LoadingOutlined /> : <PlusOutlined />}
			<div style={{ marginTop: 8 }}>Upload</div>
		</div>
	);

	return (
		<div className={styles['upload-container']}>
			<div className={styles['horizontal-align']}>
				<Upload
					listType="picture-circle"
					fileList={fileList}
					onPreview={handlePreview}
					onChange={handleChange}
					beforeUpload={beforeUpload}
				>
					{fileList.length >= 1 ? null : uploadButton}
				</Upload>

				<Button
					type="primary"
					onClick={uploadToBackend}
					disabled={fileList.length === 0 || buttonLoading}
					style={{ marginTop: 16 }}
				>
					Upload
					{buttonLoading ? <LoadingOutlined /> : ''}
				</Button>
			</div>

			<Modal
				open={previewVisible}
				title={previewTitle}
				footer={null}
				onCancel={handleCancel}
				style={{ top: '0px' }}
				closable={false}
			>
				<img
					alt="preview"
					style={{ width: '100%' }}
					src={previewImage}
				/>
			</Modal>
		</div>
	);
};

export default ImageUpload;
