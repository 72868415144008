import { Table, Tag, Tooltip } from 'antd';
import Column from 'antd/es/table/Column';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { formatDate, invalidText, toastText } from 'utils/utils';
import './index.scss';
import dayjs from 'dayjs';
import { timeLogsApi } from 'Api/timeLogs';

const MissingLogs = ({ date }: any) => {
	const [isLoading, setIsLoading] = useState(false);
	const [tableData, setTableData] = useState([]);

	const { data: profileData } = useSelector(
		(state: any) => state?.userProfile
	);

	const tableChangeHandler = (
		pagination: any,
		filter: any,
		sorter: any
	) => {};

	const fetchPendingLogs = async () => {
		try {
			setIsLoading(true);
			const data = {
				startDate: dayjs(date[0]).format('YYYY-MM-DD'),
				endDate: dayjs(date[1]).format('YYYY-MM-DD'),
			};

			const timeLogs = await timeLogsApi.getMissingTimeActivities(data);
			setTableData(timeLogs.data.data);
		} catch (error: any) {
			let message = 'Something went wrong in fetching employee details';

			if (error.response.data.message) {
				message = error.response.data.message;
			}
			toastText(message, 'error');
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		fetchPendingLogs();
	}, [date]);

	return (
		<>
			<div
				style={{
					display: 'flex',
					justifyContent: 'flex-end',
					marginBottom: '10px',
				}}
			></div>
			<Table
				className="pending-logs"
				dataSource={tableData}
				pagination={false}
				onChange={tableChangeHandler}
				rowKey={(record: any) => record.id}
				loading={isLoading}
			>
				<Column
					title="Date"
					dataIndex="date"
					key="date"
					className="bg-white"
					render={(value) => {
						return formatDate(
							value,
							profileData.companyDateFormat ?? 'DD/MM/YYYY'
						);
					}}
				/>

				<Column
					title="Total Hours"
					dataIndex="totalHours"
					key="totalHours"
					className="bg-white"
					render={(value) => {
						return <span style={{ color: 'red' }}>----</span>;
					}}
				/>

				<Column
					title={
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'start',
							}}
						>
							Attendance <br />{' '}
							<span
								style={{
									color: 'gray',
									fontWeight: 300,
									fontSize: '10px',
								}}
							>
								Present | Absent
							</span>
						</div>
					}
					dataIndex="attendance"
					key="attendance"
					className="bg-white"
					render={(value, record: any) => {
						return <span style={{ color: 'red' }}>----</span>;
					}}
				/>
				<Column
					title="Notes"
					dataIndex="notes"
					key="notes"
					className="bg-white"
					render={(value, record: any) => {
						return (
							<>
								<span>
									No shift has been created for{' '}
									{formatDate(
										record.date,
										profileData.companyDateFormat ??
											'DD/MM/YYYY'
									)}
								</span>
							</>
						);
					}}
				/>
			</Table>
		</>
	);
};

export default MissingLogs;
