import AddEmployee from 'components/AddEmployee';
import Buttons from 'components/Global/Buttons';
import TableActionHeader from 'components/Global/TableActionHeader';
import { TablePagination, TableSorter } from 'interfaces/global.interface';
import { useState, useEffect, useContext } from 'react';
import { useSearchParams } from 'react-router-dom';
import { AddSvg } from 'utils/svgs';
import EmployeeTable from './EmployeeTable';
import EmployeeHeader from './Header';
import styles from './index.module.scss';
import { getApi } from 'apis';
import { toastText } from 'utils/utils';
import { PermissionContext } from 'components/Global/AuthLayout';
import ViewEmployee from 'components/ViewEmployee';
import ConfirmDelete from 'components/Global/confirmDeleteModel';

import { employeeApi } from 'Api/employee';
import useDebounce from 'components/Global/Hooks/UseDebounce';
import { useSelector } from 'react-redux';

const Employees = () => {
	const permissionContext = useContext(PermissionContext);

	const [searchParams, setSearchParams] = useSearchParams();

	const [searchValue, setSearchValue] = useState('');
	const [statusFilterValue, setStatusFilterValue] = useState('');

	const [staffCodeFilterValue, setStaffCodeFilterValue] = useState('');

	const [employeeData, setEmployeeData] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(30);
	const [totalRecords, setTotalRecords] = useState(0);
	const [sortField, setSortField] = useState('');
	const [sortOrder, setSortOrder] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [nhimaAgeLimit, setNhimaAgeLimit] = useState(0);
	const [napsaAgeLimit, setNapsaAgeLimit] = useState(0);
	const [isAddEmployeeModalOpen, setIsAddEmployeeModalOpen] =
		useState<boolean>(false);
	const [isViewOnlyEmployeeDetails, setViewOnlyEmployeeDetails] =
		useState<boolean>(false);

	const [isDeleteLoading, setIsDeleteLoading] = useState<boolean>(false);

	const [isModalOpen, setIsModalOpen] = useState(false);
	const [deleteEmployeeId, setDeleteEmployeeId] = useState<string | null>(
		null
	);
	const debouncedSearchValue = useDebounce(searchValue, 500);
	const [supervisorFilterValue, setSupervisorFilterValue] = useState('');

	const buttons = [
		{
			text: 'Add Employee',
			isLoading: false,
			className: 'btn-blue',
			// icon: <AddSvg />,
			isSubmit: true,
			onclick: () => {
				setIsAddEmployeeModalOpen(true);
			},
			disabled: isLoading,
		},
	];

	const handleSearchApi = async (value: string) => {
		//search api
	};

	const handleSearch = (value: string) => {
		setSearchValue(value);
		setCurrentPage(1);
	};

	const handleCloseModal = () => {
		setSearchParams({});
		fetchEmployees();
		setIsAddEmployeeModalOpen(false);
		setViewOnlyEmployeeDetails(false);
	};

	const handleStatusFilter = (value: string) => {
		setStatusFilterValue(value);
		setCurrentPage(1);
	};

	const handleStaffCodeFilter = (value: string) => {
		setStaffCodeFilterValue(value);
		setCurrentPage(1);
	};


	const editEmployeeFromView = () => {
		setIsAddEmployeeModalOpen(true);
	};

	const isAdd =
		permissionContext.allowedPermissions.includes('Add_Employment') ||
		permissionContext.allowedPermissions.includes('Add_Salary') ||
		permissionContext.allowedPermissions.includes('Add_Payment_Info') ||
		permissionContext.allowedPermissions.includes('Add_Taxation') ||
		permissionContext.allowedPermissions.includes('Add_Deductions') ||
		permissionContext.allowedPermissions.includes('Add_Earnings');

	const tableChangeHandler = (
		pagination: TablePagination,
		filters: any,
		sorter: TableSorter
	) => {
		setTotalRecords(pagination.total);
		setCurrentPage(pagination.current);
		setPageSize(pagination.pageSize);
		setSortOrder(
			sorter?.order === 'ascend' ? 'asc' : sorter?.order ? 'desc' : ''
		);
		// setSortOrder(sorter.order);
		setSortField(sorter.field);
	};

	const fetchEmployees = async () => {
		try {
			setIsLoading(true);
			const query = {
				page: currentPage,
				search: debouncedSearchValue,
				status: statusFilterValue,
				sortBy: sortField,
				sortOrder: sortOrder,
				pageSize: pageSize,
				supervisorId: supervisorFilterValue,
				staffCode: staffCodeFilterValue,
			};

			const employees = await getApi('/employee', query);
			setEmployeeData(employees.data.data);
			setTotalRecords(employees.data.total);
			setNhimaAgeLimit(employees.data.nhimaAgeLimit);
			setNapsaAgeLimit(employees.data.napsaAgeLimit);
		} catch (err: any) {
			if (err.response?.data?.error?.code !== 103) {
				toastText(
					'Something went wrong in fetching employees',
					'error'
				);
			}
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		const employeeIdFromSearchParams = searchParams.get('employeeId');
		const viewOnly = searchParams.get('onlyView');

		if (employeeIdFromSearchParams && !viewOnly) {
			setIsAddEmployeeModalOpen(true);
		}
		if (employeeIdFromSearchParams && viewOnly) {
			setViewOnlyEmployeeDetails(true);
		}
		fetchEmployees();
	}, [
		currentPage,
		debouncedSearchValue,
		statusFilterValue,
		sortField,
		sortOrder,
		pageSize,
		supervisorFilterValue,
		staffCodeFilterValue,
	]);

	// Confirm operation
	const handleOk = () => {
		setIsModalOpen(false);
		setDeleteEmployeeId(null);
	};

	const handleCancel = () => {
		setIsModalOpen(false); // !isAddUserLoading &&
		setDeleteEmployeeId(null);
	};

	const showModal = () => {
		setIsModalOpen(true);
	};

	const deleteHandler = async () => {
		if (deleteEmployeeId) {
			try {
				setIsDeleteLoading(true);
				const response = await employeeApi.deleteEmployee(
					deleteEmployeeId
				);
				// if (totalRecords > 1 && totalRecords % pageSize === 1) {
				// 	setCurrentPage(currentPage - 1);
				// }
				handleCancel();
				fetchEmployees();
				let message =
					response?.data?.message || 'Employee deleted successfully';

				toastText(message, 'success');
			} catch (error) {
				toastText('Something went wrong in delete Employee', 'error');
			} finally {
				setIsDeleteLoading(false);
			}
		}
	};

	return (
		<div
			style={{
				maxHeight: 'calc(100vh - 100px)',
			}}
		>
			<TableActionHeader title={'Employees'}>
				{isAdd && (
					<div>
						<Buttons buttons={buttons} />
					</div>
				)}
			</TableActionHeader>
			<div className={styles['employee-container']}>
				<EmployeeHeader
					searchValue={searchValue}
					handleSearch={handleSearch}
					statusFilterValue={statusFilterValue}
					handleStatusFilter={handleStatusFilter}
					// supervisorFilterValue={supervisorFilterValue}
					// handleSupervisorFilter={handleSupervisorFilter}
					// options={dropdownOptions?.supervisors}
					handleSearchApi={handleSearchApi}
					fetchRecords={fetchEmployees}
					staffCodeFilterValue={staffCodeFilterValue}
					handleStaffCodeFilter={handleStaffCodeFilter}
					fetchEmployees={fetchEmployees}
					isLoading={isLoading}
				/>
				<div className={styles['employee-table-container']}>
					<EmployeeTable
						employeeData={employeeData}
						totalRecords={totalRecords}
						pageSize={pageSize}
						currentPage={currentPage}
						tableChangeHandler={tableChangeHandler}
						fetchRecords={fetchEmployees}
						isLoading={isLoading}
						setIsAddEmployeeModalOpen={setIsAddEmployeeModalOpen}
						setViewOnlyEmployeeDetails={setViewOnlyEmployeeDetails}
						showModal={showModal}
						setDeleteEmployee={setDeleteEmployeeId}
					/>
				</div>
			</div>
			{isAddEmployeeModalOpen && (
				<AddEmployee
					onCancel={handleCloseModal}
					isAddEmployeeModalOpen={isAddEmployeeModalOpen}
					fetchEmployees={fetchEmployees}
					nhimaAgeLimit={nhimaAgeLimit}
					napsaAgeLimit={napsaAgeLimit}
				/>
			)}
			{isViewOnlyEmployeeDetails && (
				<ViewEmployee
					onCancel={handleCloseModal}
					isViewOnlyEmployeeDetails={isViewOnlyEmployeeDetails}
					isEditEmployeeFromView={editEmployeeFromView}
					fetchEmployees={fetchEmployees}
				/>
			)}
			{/* {isDeleteModalOpen && ( */}
			<ConfirmDelete
				isModalOpen={isModalOpen}
				handleCancel={handleCancel}
				deleteHandler={deleteHandler}
				isLoading={isDeleteLoading}
				handleOk={handleOk}
			/>
			{/* )} */}
		</div>
	);
};

export default Employees;
