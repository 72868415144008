import { Modal, Table } from 'antd';
import Column from 'antd/es/table/Column';
import Ellipse from 'components/Global/Ellipse';
import dayjs from 'dayjs';
import { useState } from 'react';

type GlobalInformationModalProps = {
	open: boolean;
	InformationData: any;
	closeInformationModal: () => void;
	totalRecords: number;
	currentPage: number;
	pageSize: number;
	tableChangeHandler: (pagination: any, filter: any, sorter: any) => void;
	loading: boolean;
	downloadCommunication?: (communicationId: string) => Promise<void>;
	setDownloadingRowId: (id: string | null) => void;
	downloadingRowId: string | null;
};

const GlobalInformationModal = (props: GlobalInformationModalProps) => {
	const {
		open,
		InformationData,
		closeInformationModal,
		totalRecords,
		currentPage,
		pageSize,
		tableChangeHandler,
		loading,
		downloadingRowId,
		setDownloadingRowId,
		downloadCommunication,
	} = props;

	const handleDownload = async (communicationId: string) => {
		setDownloadingRowId(communicationId);

		if (downloadCommunication) {
			await downloadCommunication(communicationId);
		}

		setDownloadingRowId(null);
	};

	return (
		<Modal
			open={open}
			maskClosable={true}
			width={1000}
			footer={null}
			onCancel={closeInformationModal}
		>
			<Table
				dataSource={InformationData}
				pagination={{
					total: totalRecords,
					current: currentPage,
					pageSize: pageSize,
					showSizeChanger: false,
					pageSizeOptions: [10, 20, 50, 100, 200],
				}}
				onChange={tableChangeHandler}
				rowKey={(record) => record.id}
				loading={loading}
			>
				<Column
					title="Date"
					dataIndex="createdAt"
					key="createdAt"
					render={(text: any) => dayjs(text).format('DD/MM/YYYY')}
					sorter={true}
					className="bg-white"
					width={'33%'}
				/>
				<Column
					title="Title"
					dataIndex="docTitle"
					key="docTitle"
					width={'33%'}
					className="bg-white"
				/>
				<Column
					title="Attachment"
					dataIndex="documentLink"
					key="documentLink"
					className="bg-white"
					width={'33%'}
					render={(text, record: any) => (
						<div>
							{record.documentName ? (
								<div>
									<div
										onClick={() =>
											downloadingRowId === record.id
												? () => {}
												: handleDownload(record.id)
										}
										style={{
											cursor:
												downloadingRowId === record.id
													? 'not-allowed'
													: 'pointer',
											opacity:
												downloadingRowId === record.id
													? 0.5
													: 1,
										}}
									>
										<Ellipse
											message={record.documentName}
											maxLength={50}
											key={record.documentName}
											tooltipMessage={record.documentName}
											isTooltip={true}
											isLink={true}
										/>
									</div>
									{/* Show progress bar only for the downloading row */}
									{/* {downloadingRowId === record.id && (
										<Progress
											percent={downloadProgress}
											size="small"
											style={{ marginTop: 8 }}
										/>
									)} */}
								</div>
							) : (
								'-'
							)}
						</div>
					)}
				/>
			</Table>
		</Modal>
	);
};

export default GlobalInformationModal;
