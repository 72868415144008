import { CloseOutlined } from '@ant-design/icons';
import { skillLevyApi } from 'Api/skillLevy';
import { Modal, Table, Tooltip } from 'antd';
import Column from 'antd/es/table/Column';
import Title from 'antd/es/typography/Title';
import Ellipse from 'components/Global/Ellipse';
import dayjs from 'dayjs';
import { useState } from 'react';
import { formatDate, handleDownload, invalidText } from 'utils/utils';
import './index.scss';
import { useSelector } from 'react-redux';


type HistoryProps = {
	open: boolean;
	closeModal: () => void;
	totalRecords: number;
	currentPage: number;
	pageSize: number;
	tableChangeHandler: (pagination: any, filter: any, sorter: any) => void;
	allSkillLevyData: any;
	isLoading: boolean;
	fetchAllSkillLevyData: any;
};

const HistoryModal = (props: HistoryProps) => {
	const {
		open,
		closeModal,
		totalRecords,
		currentPage,
		pageSize,
		tableChangeHandler,
		allSkillLevyData,
		isLoading,
	} = props;

	const [downloadingRowId, setDownloadingRowId] = useState<string | null>(
		null
	);

	const { data: profileData } = useSelector(
		(state: any) => state?.userProfile
	);


	const handleRecordDownload = async (
		recordId: string,
		recordName: string
	) => {
		return await handleDownload(
			recordId,
			skillLevyApi.downloadSkillLevyAttachment,
			setDownloadingRowId,
			recordName
		);
	};

	return (
		<>
			<Modal
				open={open}
				maskClosable={true}
				width={1000}
				closable={false}
				footer={null}
				rootClassName="skillModalRoot"
			>
				<div className="modal-header">
					<Title level={4} className="modal-title">
						History
					</Title>
					<div className="close-icon" onClick={closeModal}>
						<CloseOutlined />
					</div>
				</div>
				<Table
					dataSource={allSkillLevyData}
					pagination={{
						total: totalRecords,
						current: currentPage,
						pageSize: pageSize,
						showSizeChanger: false,

						pageSizeOptions: [10, 20, 50, 100, 200],
					}}
					onChange={tableChangeHandler}
					rowKey={(record) => record.id}
					loading={isLoading}
				>
					<Column
						title="Effective Date"
						dataIndex="effectiveDate"
						key="effectiveDate"
						sorter={true}
						className="bg-white"
						render={(value) => {
							return formatDate(
								value,
								profileData.companyDateFormat ?? 'DD/MM/YYYY'
							);
						}}
					/>

					<Column
						title="Total Percentage Gross"
						dataIndex="grossPercentage"
						key="grossPercentage"
						className="bg-white"
					/>
					<Column
						title="Notes"
						dataIndex="notes"
						key="notes"
						sorter={true}
						className="bg-white"
						render={(text) =>
							invalidText(text) ? (
								'-'
							) : (
								<Ellipse
									message={text}
									maxLength={20}
									key={text}
									tooltipMessage={text}
									isTooltip={true}
								/>
							)
						}
					/>

					<Column
						title="Attachment"
						dataIndex="attachment"
						key="attachment"
						className="bg-white"
						width={'18%'}
						render={(text, record: any) => (
							<div>
								{record.documentName ? (
									<div>
										<div
											onClick={() =>
												downloadingRowId === record.id
													? () => {}
													: handleRecordDownload(
															record.id,
															record.documentName
													  )
											}
											style={{
												cursor:
													downloadingRowId ===
													record.id
														? 'not-allowed'
														: 'pointer',
												opacity:
													downloadingRowId ===
													record.id
														? 0.5
														: 1,
											}}
										>
											<Ellipse
												message={record.documentName}
												maxLength={20}
												key={record.documentName}
												tooltipMessage={
													record.documentName
												}
												isTooltip={true}
												isLink={true}
											/>
										</div>
									</div>
								) : (
									'-'
								)}
							</div>
						)}
					/>
				</Table>
			</Modal>
		</>
	);
};

export default HistoryModal;
